// src/components/form/NavigatorForm.tsx

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  Box,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { NavigatorFormData, CategorizedInstrument, CATEGORIZED_INSTRUMENTS, Instrument } from '../../types/NavigatorTypes';
import { IrishCounty } from '../../types/BandRecommendations';
import { SelectChangeEvent } from '@mui/material/Select';
import PrePopulateCheckbox from './PrePopulateCheckbox';
import SaveDraftButton from './SaveDraftButton';
import axiosInstance from '../../utils/axiosConfig';
import { useSearchParams } from 'react-router-dom';
import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
} from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';

// Styles
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    '&.Mui-required': {
      '&::after': {
        content: '" *"',
        color: theme.palette.error.main,
      },
    },
  },
  '& .MuiFormHelperText-root': {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

const ProgressSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiLinearProgress-root': {
    height: 8,
    borderRadius: 4,
  },
}));

const ValidationMessage = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(0.5),
  fontSize: '0.75rem',
}));

// Types
interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  instrument?: string;
  linkToWork?: string;
  webLink?: string;
  residencyGoals?: string;
  locationDates?: string;
}

interface FormSection {
  title: string;
  fields: string[];
  description: string;
  complete: boolean;
}

const dateRanges = [
  'Ballina Arts Centre - June-August 2025',
  'The Source Arts Centre, Thurles - May-June 2025',
  'An Grianán Theatre, Letterkenny - June-July 2025',
  'Courthouse Arts, Tinahely - TBC 2025',
  'Garter Lane, Waterford - TBC 2025',
  'Riverbank Arts Centre, Kildare. - TBC 2025',
  'Any of the above! - See Date Options Listed above',
];

const MAX_INSTRUMENTS = 3;

const formSections: FormSection[] = [
  {
    title: 'Personal Information',
    fields: ['name', 'email', 'phone'],
    description: 'Basic contact information',
    complete: false,
  },
  {
    title: 'Musical Background',
    fields: ['instrument', 'linkToWork', 'webLink'],
    description: 'Your musical instrument(s) and online presence',
    complete: false,
  },
  {
    title: 'Location & Availability',
    fields: ['location', 'locationDates'],
    description: 'Where you are based and your availability',
    complete: false,
  },
  {
    title: 'Main Information',
    fields: ['residencyGoals', 'parentalDuties'],
    description: 'Your main goals and any support requirements',
    complete: false,
  },
];

const NavigatorForm: React.FC = () => {
  // State
  const [formData, setFormData] = useState<NavigatorFormData>({
    name: '',
    email: '',
    phone: '',
    instrument: [] as Instrument[],
    location: IrishCounty.Dublin,
    pronouns: '',
    linkToWork: '',
    webLink: '',
    residencyGoals: '',
    locationDates: [],
    parentalDuties: '',
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<FormErrors>({});
  const [activeSection, setActiveSection] = useState(0);
  const [touched, setTouched] = useState<Set<string>>(new Set());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);

  const onFormClick = (e: React.FormEvent) => {
    console.log("Form clicked", e);
  };

  const submitForm = async () => {
    console.log("Direct form submission attempt");
    try {
      const response = await axiosInstance.post('/api/navigator', formData);
      console.log("Submission successful:", response);
      // ... rest of success handling
    } catch (error) {
      console.error("Direct submission error:", error);
    }
  };
  


  // URL params for draft handling
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get('draft');

  // Validation Rules
  const validateField = (name: string, value: any): string => {
    switch (name) {
      case 'name':
        // Allow letters, spaces, hyphens, apostrophes, and international characters
        const nameRegex = /^[\p{L} '-]+$/u;
        return !value?.trim()
          ? 'Name is required'
          : !nameRegex.test(value)
            ? 'Please enter a valid name'
            : '';

      case 'email':
        return !value?.trim() 
          ? 'Email is required' 
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? 'Please enter a valid email address'
            : '';

      case 'phone':
        // More lenient phone validation - accepts international formats
        return !value?.trim() 
          ? 'Phone number is required'
          : !/^[0-9+\-\s().]{8,}$/.test(value.replace(/\s+/g, ''))
            ? 'Please enter a valid phone number'
            : '';

            case 'instrument':
  const instruments = value as Instrument[];
  if (!Array.isArray(instruments) || instruments.length === 0) {
    return 'Please select at least one instrument';
  }
  if (instruments.length > MAX_INSTRUMENTS) {
    return `Maximum ${MAX_INSTRUMENTS} instruments allowed`;
  }
  return '';

      case 'linkToWork':
        return !value?.trim()
          ? 'Please provide a link to your work'
          : '';

      case 'residencyGoals':
        // Check if it's empty or just the placeholder text
        if (!value || value.trim() === '' || value === 'Here is bio information') {
          return 'Please describe your residency goals';
        }
        // Check for minimum meaningful length
        if (value.trim().length < 50) {
          return 'Please provide more detail (minimum 50 characters)';
        }
        return '';

        case 'locationDates':
          const dates = value as string[];
          // Changed to check for array and minimum length
          if (!Array.isArray(dates) || dates.length === 0) {
            return 'Please select at least one residency option';
          }

      default:
        return '';
    }
  };

  // Effect to validate all touched fields whenever formData changes
  useEffect(() => {
    const validateAllTouchedFields = () => {
      const newErrors: FormErrors = { ...errors };

      touched.forEach(field => {
        const value = formData[field as keyof NavigatorFormData];
        const error = validateField(field, value);
        if (error) {
          newErrors[field as keyof FormErrors] = error;
        } else {
          delete newErrors[field as keyof FormErrors];
        }
      });

      setErrors(newErrors);
    };

    validateAllTouchedFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // Load draft if available
  useEffect(() => {
    const loadDraft = async () => {
      if (draftId) {
        try {
          const response = await axiosInstance.get(`/api/applications/drafts/${draftId}`);
          setFormData(response.data.formData);
        } catch (error) {
          console.error('Error loading draft:', error);
        }
      }
    };
    loadDraft();
  }, [draftId]);

  // Auto-save functionality
  useEffect(() => {
    if (!autoSaveEnabled || !formData.email || !draftId) return;

    const timer = setTimeout(async () => {
      try {
        await axiosInstance.put(`/api/applications/drafts/${draftId}`, {
          formData,
          lastSaved: new Date()
        });
      } catch (error) {
        console.error('Error auto-saving:', error);
      }
    }, 60000); // Auto-save every 60 seconds

    return () => clearTimeout(timer);
  }, [formData, autoSaveEnabled, draftId]);

  // Handlers
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    
    // Mark the field as touched
    setTouched(prev => new Set(prev).add(name));
    
    // For the 'webLink' field, ensure it starts with 'http://' or 'https://'
    if (name === 'webLink' && value && !/^https?:\/\//i.test(value)) {
      setFormData(prev => ({
        ...prev,
        [name]: `https://${value}`,
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
    
    // Clear any existing errors for the field
    if (errors && (name in errors)) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const { name, value } = event.target;
    setTouched(prev => new Set(prev).add(name));

    if (name === 'locationDates') {
      setFormData(prev => ({
        ...prev,
        locationDates: typeof value === 'string' ? value.split(',') : value as string[],
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleInstrumentChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: CategorizedInstrument[]
  ) => {
    setTouched(prev => new Set(prev).add('instrument'));
    
    // Convert CategorizedInstrument objects to Instrument enum values
    const instrumentValues = newValue.map(item => item.value as Instrument);
  
    setFormData(prev => ({
      ...prev,
      instrument: instrumentValues.slice(0, MAX_INSTRUMENTS),
    }));
  };

  const handlePrePopulate = (profileData: any) => {
    setFormData(prev => ({
      ...prev,
      ...profileData
    }));
  };

  const handleSaveSuccess = () => {
    setSuccessMessage('Draft saved successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const calculateFormProgress = (): number => {
    const requiredFields = Object.keys(formData).filter(key => 
      key !== 'parentalDuties' && key !== 'pronouns'
    );
    
    const completedFields = requiredFields.filter(key => {
      const value = formData[key as keyof NavigatorFormData];
      return !validateField(key, value);
    });

    return (completedFields.length / requiredFields.length) * 100;
  };

  const isSectionComplete = (sectionIndex: number): boolean => {
    const section = formSections[sectionIndex];
    return section.fields.every(field => {
      const value = formData[field as keyof NavigatorFormData];
      return !validateField(field, value);
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Starting form submission");
    
    // Log the current state
    console.log("Current form state:", {
      formData,
      errors,
      touched: Array.from(touched),
      isSubmitting,
      activeSection
    });
    console.log("Current form data:", formData);
    console.log("Form submitted"); // Add this line
  
    // Touch all fields to trigger validation
    const allFields = Object.keys(formData);
    setTouched(new Set(allFields));
  
    // Validate all fields
    const newErrors: FormErrors = {};
    allFields.forEach(field => {
      const value = formData[field as keyof NavigatorFormData];
      const error = validateField(field, value);
      if (error) newErrors[field as keyof FormErrors] = error;
    });
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setSubmissionError('Please fix the errors before submitting');
      return;
    }
  
    setIsSubmitting(true);
    setSubmissionError('');
  
    try {
      const response = await axiosInstance.post('/api/navigator', formData);
      
      // Assuming the backend returns an object ID in response.data.id
      const referenceId = response.data.id;
  
      // If this was a draft, delete it
      if (draftId) {
        await axiosInstance.delete(`/api/applications/drafts/${draftId}`);
      }
  
      // Redirect to the success page with referenceId
      navigate('/submission-success', { state: { referenceId } });
      
      // Optionally, reset the form
      setFormData({
        name: '',
        email: '',
        phone: '',
        instrument: [],
        location: IrishCounty.Dublin,
        pronouns: '',
        linkToWork: '',
        webLink: '',
        residencyGoals: '',
        locationDates: [],
        parentalDuties: '',
      });
      setTouched(new Set());
      setErrors({});
    } catch (error: any) {
      console.error('Submission error:', error);
      setSubmissionError(
        error.response?.data?.message || 'Error submitting application. Please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper Components
  const renderFieldError = (fieldName: keyof FormErrors) => {
    const error = errors[fieldName];
    if (!touched.has(fieldName)) return null;
    
    return error ? (
      <ValidationMessage color="error">
        <ErrorOutline fontSize="small" />
        {error}
      </ValidationMessage>
    ) : (
      <ValidationMessage color="success">
        <CheckCircleOutline fontSize="small" />
        Field complete
      </ValidationMessage>
    );
  };

  return (
    <form 
  ref={formRef}
  onSubmit={(e) => {
    console.log("Form onSubmit triggered");
    handleSubmit(e);
  }}
  onClick={onFormClick}
  aria-labelledby="navigator-form-title"
>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* You can add a form title or description here if needed */}
        </Grid>

        {/* Progress Indicator */}
        <Grid item xs={12}>
          <ProgressSection>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body2">
                Application Progress
              </Typography>
              <Typography variant="body2">
                {Math.round(calculateFormProgress())}% Complete
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={calculateFormProgress()} 
            />
          </ProgressSection>
        </Grid>

        {/* Form Sections Stepper */}
        <Grid item xs={12}>
          <Stepper activeStep={activeSection} alternativeLabel>
            {formSections.map((section, index) => (
              <Step key={section.title} completed={isSectionComplete(index)}>
                <StepLabel>{section.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

{/* Action Buttons */}
<Grid item xs={12}>
  <Box display="flex" gap={2}>
    <PrePopulateCheckbox onPrePopulate={handlePrePopulate} />
    {user && (user.role === 'admin' || user.role === 'artist') && (
      <SaveDraftButton
        formData={formData}
        opportunityType="navigator"
        deadline="2024-12-31"
        onSaveSuccess={handleSaveSuccess}
      />
    )}
  </Box>
</Grid>

        {/* Messages */}
        {(successMessage || submissionError) && (
          <Grid item xs={12}>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {submissionError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {submissionError}
              </Alert>
            )}
          </Grid>
        )}

        {/* Personal Information Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formSections[0].title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              {formSections[0].description}
            </Typography>
            <Grid container spacing={3}>
              {/* Musician's Name */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth error={!!errors.name}>
                  <TextField
                    required
                    fullWidth
                    name="name"
                    label="Musician's Name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name && touched.has('name')}
                    onBlur={() => setTouched(prev => new Set(prev).add('name'))}
                  />
                  {renderFieldError('name')}
                </StyledFormControl>
              </Grid>

              {/* Phone */}
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.phone}>
                  <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone && touched.has('phone')}
                    onBlur={() => setTouched(prev => new Set(prev).add('phone'))}
                    InputProps={{
                      startAdornment: <Typography variant="body2">+</Typography>,
                    }}
                  />
                  {renderFieldError('phone')}
                </StyledFormControl>
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.email}>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email && touched.has('email')}
                    onBlur={() => setTouched(prev => new Set(prev).add('email'))}
                  />
                  {renderFieldError('email')}
                </StyledFormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Musical Background Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formSections[1].title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              {formSections[1].description}
            </Typography>
            <Grid container spacing={3}>
            {/* Instrument Selection */}
            <Grid item xs={12}>
  <StyledFormControl fullWidth error={!!errors.instrument}>
    <Autocomplete
      multiple
      id="instrument"
      options={CATEGORIZED_INSTRUMENTS}
      value={CATEGORIZED_INSTRUMENTS.filter(item => 
        formData.instrument.includes(item.value as Instrument)
      )}
      onChange={handleInstrumentChange}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="Instruments"
          error={!!errors.instrument && touched.has('instrument')}
          onBlur={() => setTouched(prev => new Set(prev).add('instrument'))}
          helperText={`Select up to ${MAX_INSTRUMENTS} primary instruments`}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox 
            checked={selected} 
            sx={{ mr: 1 }} 
            disabled={!selected && formData.instrument.length >= MAX_INSTRUMENTS}
          />
          <Box>
            <Typography>
              {option.label}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ ml: 1 }}
            >
              {option.category}
            </Typography>
          </Box>
        </li>
      )}
      renderGroup={(params) => (
        <Box key={params.key}>
          <Typography
            variant="subtitle2"
            sx={{
              px: 2,
              py: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            {params.group}
          </Typography>
          {params.children}
        </Box>
      )}
      ChipProps={{
        size: "small"
      }}
    />
    {renderFieldError('instrument')}
    <FormHelperText>
      <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
      Select up to {MAX_INSTRUMENTS} primary instruments. Begin typing to find your instrument in the dropdown. 
      Instruments are grouped by category. Choose Other if your instrument is not listed.
    </FormHelperText>
  </StyledFormControl>
</Grid>

              {/* Link to Work */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth error={!!errors.linkToWork}>
                  <TextField
                    required
                    fullWidth
                    name="linkToWork"
                    label="Link to Work"
                    value={formData.linkToWork}
                    onChange={handleChange}
                    error={!!errors.linkToWork && touched.has('linkToWork')}
                    onBlur={() => setTouched(prev => new Set(prev).add('linkToWork'))}
                    placeholder="https://"
                  />
                  {renderFieldError('linkToWork')}
                  <FormHelperText>
                    <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
                    Provide a link to your music (SoundCloud, YouTube, etc.)
                  </FormHelperText>
                </StyledFormControl>
              </Grid>

              {/* Web Link */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth>
                  <TextField
                    fullWidth
                    name="webLink"
                    label="Website or Social Media"
                    value={formData.webLink}
                    onChange={handleChange}
                    placeholder="https://"
                  />
                  <FormHelperText>
                    <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
                    Optional: Your website or main social media presence
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Location & Availability Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formSections[2].title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              {formSections[2].description}
            </Typography>
            <Grid container spacing={3}>
              {/* Location */}
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <InputLabel id="location-label">Location</InputLabel>
                  <Select
                    labelId="location-label"
                    name="location"
                    value={formData.location}
                    onChange={handleSelectChange}
                    label="Location"
                  >
                    {Object.values(IrishCounty).map((county) => (
                      <MenuItem key={county} value={county}>
                        {county}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Your primary location in Ireland
                  </FormHelperText>
                </StyledFormControl>
              </Grid>

              {/* Pronouns */}
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth>
                  <TextField
                    fullWidth
                    name="pronouns"
                    label="Pronouns"
                    value={formData.pronouns}
                    onChange={handleChange}
                    onBlur={() => setTouched(prev => new Set(prev).add('pronouns'))}
                    placeholder="e.g., he/him, she/her, they/them, etc."
                  />
                  <FormHelperText>
                    Optional: Share your preferred pronouns
                  </FormHelperText>
                </StyledFormControl>
              </Grid>

              {/* Location Dates */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth error={!!errors.locationDates}>
                  <InputLabel id="locationDates-label">
                    Please tick the residency’s you are interested in applying for
                  </InputLabel>
                  <Select
                    labelId="locationDates-label"
                    name="locationDates"
                    multiple
                    value={formData.locationDates}
                    onChange={handleSelectChange}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    label="Please tick the residency’s you are interested in applying for"
                    error={!!errors.locationDates && touched.has('locationDates')}
                    onBlur={() => setTouched(prev => new Set(prev).add('locationDates'))}
                  >
                    {dateRanges.map((dateRange) => (
                      <MenuItem key={dateRange} value={dateRange}>
                        <Checkbox checked={formData.locationDates.includes(dateRange)} />
                        <ListItemText primary={dateRange} />
                      </MenuItem>
                    ))}
                  </Select>
                  {renderFieldError('locationDates')}
                  <FormHelperText>
                    <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
                    Select all residency options you are interested in applying for
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Additional Information Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formSections[3].title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              {formSections[3].description}
            </Typography>
            <Grid container spacing={3}>
              {/* Residency Goals */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth error={!!errors.residencyGoals}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={4}
                    name="residencyGoals"
                    label="Residency Goals"
                    value={formData.residencyGoals}
                    onChange={handleChange}
                    placeholder="Describe your artistic goals, planned activities, and how you intend to engage with the local community during your residency..."
                    error={!!errors.residencyGoals && touched.has('residencyGoals')}
                    onBlur={() => setTouched(prev => new Set(prev).add('residencyGoals'))}
                  />
                  {renderFieldError('residencyGoals')}
                  <FormHelperText>
                    <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
                    Describe your goals for the residency and how you plan to engage with the community
                  </FormHelperText>
                </StyledFormControl>
              </Grid>

              {/* Parental Duties Support */}
              <Grid item xs={12}>
                <StyledFormControl fullWidth>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="parentalDuties"
                    label="Parental or Caring Duties Support"
                    value={formData.parentalDuties}
                    onChange={handleChange}
                  />
                  <FormHelperText>
                    <InfoOutlined sx={{ mr: 1, fontSize: '1rem' }} />
                    Optional: Describe any parental or caring support needs during the residency
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              {calculateFormProgress() < 100 && (
                <Typography variant="body2" color="error">
                  Please complete all required fields before submitting
                </Typography>
              )}
            </Box>
            <Button
  type="submit"
  variant="contained"
  color="primary"
  size="large"
  disabled={isSubmitting || calculateFormProgress() < 100}
  onClick={async (e) => {
    e.preventDefault();
    console.log("Submit button clicked - attempting both methods");
    // Try direct submission
    await submitForm();
    // Also try form submit
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }}
>
  {isSubmitting ? 'Submitting...' : 'Submit Application'}
</Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default NavigatorForm;