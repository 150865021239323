// src/components/form/SaveDraftButton.tsx
import React, { useState } from 'react';
import { Button, Typography, FormControlLabel, styled } from '@mui/material';
import { Save } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import { NavigatorFormData } from '../../types/NavigatorTypes';
import { useAuth } from '../../contexts/AuthContext'; // Add this import


const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 0,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    // Change to a darker, more subtle background
    backgroundColor: 'rgba(255, 255, 255, 0.05)', // subtle white overlay
    border: '1px solid rgba(255, 255, 255, 0.1)', // subtle border
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)', // slightly lighter on hover
    },
    // Ensure text is visible
    '& .MuiTypography-root': {
      color: 'rgba(255, 255, 255, 0.87)', // light text for dark background
    },
    // Style the button
    '& .MuiButton-root': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      }
    }
  }));

interface SaveDraftButtonProps {
  formData: NavigatorFormData;
  opportunityType: 'navigator' | 'other-opportunity-types';
  deadline: string;
  onSaveSuccess: () => void;
}

const SaveDraftButton: React.FC<SaveDraftButtonProps> = ({
  formData,
  opportunityType,
  deadline,
  onSaveSuccess
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth(); // Get the user from auth context
 // If user is not logged in or not an admin/artist, don't render anything
 if (!user || (user.role !== 'admin' && user.role !== 'artist')) {
    return null;
  }

  const handleSaveDraft = async () => {
    setIsSaving(true);
    try {
      const draftsResponse = await axiosInstance.get('/api/applications/drafts');
      const existingDraft = draftsResponse.data.find(
        (draft: any) => draft.type === opportunityType
      );
  
      if (existingDraft) {
        await axiosInstance.put(`/api/applications/drafts/${existingDraft._id}`, {
          formData,
          lastSaved: new Date(),
          isComplete: false
        });
      } else {
        await axiosInstance.post('/api/applications/drafts', {
          type: opportunityType,
          deadline,
          formData,
          lastSaved: new Date(),
          isComplete: false
        });
      }
      onSaveSuccess();
    } catch (error) {
      console.error('Error saving draft:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledFormControlLabel
      control={
        <Button 
          onClick={handleSaveDraft}
          startIcon={<Save />}
          disabled={isSaving}
          variant="outlined"
          color="secondary"
        >
          {isSaving ? 'Saving...' : 'Save Draft'}
        </Button>
      }
      label={
        <Typography variant="body2" color="textSecondary">
          Save application to complete later
        </Typography>
      }
    />
  );
};

export default SaveDraftButton;