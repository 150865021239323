import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, CircularProgress, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { green } from '@mui/material/colors';
import axios from 'axios';
import { IrishCounty } from '../../types/IrishCounty';

interface PrePopulateCheckboxProps {
  onPrePopulate: (profileData: any) => void;
}

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: green[300],
  '&.Mui-checked': {
    color: green[500],
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: theme.spacing(2),
  padding: theme.spacing(1.5), // Increased padding
  marginTop: theme.spacing(2), // Added top margin
  marginBottom: theme.spacing(5), // Added bottom margin
  borderRadius: theme.shape.borderRadius,
  backgroundColor: green[50],
  '&:hover': {
    backgroundColor: green[100],
  },
}));

const PrePopulateCheckbox: React.FC<PrePopulateCheckboxProps> = ({ onPrePopulate }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasArtistProfile, setHasArtistProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false); // <-- Add this state

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
      if (!token) {
        setIsLoggedIn(false);
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoggedIn(true);
      setHasArtistProfile(response.data.isArtist);
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrePopulate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
  
    if (isChecked) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const profileData = response.data;
  
        // Split the name into firstName and lastName
        const fullName = profileData.name || '';
        const [firstName, ...lastNameParts] = fullName.split(' ');
        const lastName = lastNameParts.join(' ');
  
        // Get the suffix from one of your form fields
        const suffix = "-1733247983507"; // This should match your form field IDs
  
        // Transform the data with the correct field IDs
        const transformedData = {
          [`firstName${suffix}`]: firstName || '',
          [`lastName${suffix}`]: lastName || '',
          [`email${suffix}`]: profileData.email || '',
          [`streetAddress1${suffix}`]: profileData.addressLine1 || '',
          [`streetAddress2${suffix}`]: profileData.addressLine2 || '',
          [`city${suffix}`]: profileData.city || '',
          [`county${suffix}`]: profileData.county || 'Dublin',
          [`postcode${suffix}`]: profileData.postcode || '',
          [`phone${suffix}`]: profileData.phoneNumber || '',
          [`websiteOrSocial1${suffix}`]: profileData.website || profileData.facebookPage || profileData.instagramHandle || '',
          [`websiteOrSocial2${suffix}`]: profileData.twitterHandle || ''
        };
  
        console.log('Transformed data with suffixes:', transformedData);
        onPrePopulate(transformedData);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    } else {
      onPrePopulate({});
    }
  };

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  if (!isLoggedIn || !hasArtistProfile) {
    return null;
  }

  return (
    <StyledFormControlLabel
      control={
        <GreenCheckbox
          onChange={handlePrePopulate}
          icon={<AutoFixHighIcon />}
          checkedIcon={<AutoFixHighIcon />}
          checked={checked} // <-- Bind the checked state
        />
      }
      label={
        <Box display="flex" alignItems="center">
          <Typography variant="body2" fontWeight="bold" color="primary">
            Pre-populate with my artist profile
          </Typography>
        </Box>
      }
    />
  );
};

export default PrePopulateCheckbox;