import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Button, Stack } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import { SignatureConfig } from '../../types/FormSchema';

interface SignatureFieldProps {
  config: SignatureConfig;
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const SignatureField: React.FC<SignatureFieldProps> = ({
  config,
  onChange,
  value,
  disabled = false,
  error = false,
  helperText
}) => {
  const sigPad = useRef<SignatureCanvas>(null);
  const [isEmpty, setIsEmpty] = useState(true);

  // Initialize with existing value if provided
  React.useEffect(() => {
    if (value && sigPad.current) {
      sigPad.current.fromDataURL(value);
      setIsEmpty(false);
    }
  }, [value]);

  const handleClear = () => {
    if (sigPad.current) {
      sigPad.current.clear();
      setIsEmpty(true);
      onChange('');
    }
  };

  const handleSave = () => {
    if (sigPad.current && !sigPad.current.isEmpty()) {
      const dataUrl = sigPad.current.toDataURL('image/png');
      onChange(dataUrl);
    }
  };

  const handleBegin = () => {
    setIsEmpty(false);
  };

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
      <Box
        sx={{
          border: theme => error 
            ? `2px solid ${theme.palette.error.main}`
            : `1px solid ${disabled ? 'rgba(0,0,0,0.23)' : 'rgba(0,0,0,0.87)'}`,
          borderRadius: 1,
          backgroundColor: config.backgroundColor,
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <SignatureCanvas
          ref={sigPad}
          canvasProps={{
            height: config.canvasHeight,
            className: 'signature-canvas',
            style: {
              width: '100%',
              maxWidth: '100%'
            }
          }}
          backgroundColor={config.backgroundColor}
          penColor={config.penColor}
          dotSize={config.penSize}
          onBegin={handleBegin}
        />
      </Box>

      {helperText && (
        <Box sx={{ 
          color: error ? 'error.main' : 'text.secondary',
          fontSize: '0.75rem',
          mt: 0.5
        }}>
          {helperText}
        </Box>
      )}

      <Stack direction="row" spacing={1}>
        {config.clearable && (
          <Button
            startIcon={<UndoIcon />}
            onClick={handleClear}
            disabled={disabled || isEmpty}
            variant="outlined"
            size="small"
          >
            Clear
          </Button>
        )}
        <Button
          startIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={disabled || isEmpty}
          variant="contained"
          size="small"
          color="primary"
        >
          Save Signature
        </Button>
      </Stack>

      <style>{`
        .signature-canvas {
          touch-action: none;
        }
      `}</style>
    </Box>
  );
};

export default SignatureField;