import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Button, Chip, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig'; // Changed to default import

interface Strand {
  _id: string;
  name: string;
  description: string;
  type: string;
  startDate: string;
  endDate: string;
  eventType: string;
  status: string;
  published: boolean;
  slug: string;
}

interface Guideline {
  strandId: string;
  strandSlug: string;
  currentVersion: number;
  isPublished: boolean;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.imc.show';

const strandRoutes: { [key: string]: string } = {
  'Better Live': '/recommend',
  'Navigator': '/navigator',
  'Ban Bam': '/banbam',
};

const PublicStrandsPage: React.FC = () => {
  const navigate = useNavigate();
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Just fetch strands - we'll check guidelines when clicking the button
        const strandsResponse = await axios.get<Strand[]>(`${API_BASE_URL}/api/strands`);
        const publishedStrands = strandsResponse.data.filter(strand => strand.published);
        setStrands(publishedStrands);
      } catch (error) {
        console.error('Error fetching strands:', error);
        setError('Failed to fetch opportunities. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleApply = (strand: Strand) => {
    const route = strandRoutes[strand.name] || '/recommend';
    navigate(route);
  };

  const handleGuidelines = (strand: Strand) => {
    // Simply navigate to the guidelines page - the guidelines component will handle the fetching
    navigate(`/guidelines/strand/${strand.slug}`);
  };

  const getRemainingDays = (endDate: string) => {
    const today = new Date();
    const deadline = new Date(endDate);
    const diffTime = deadline.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const RemainingDaysChip = ({ days }: { days: number }) => {
    let color = 'default';
    let label = `${days} days left`;

    if (days > 30) {
      color = 'success';
    } else if (days > 14) {
      color = 'warning';
    } else if (days > 0) {
      color = 'error';
    } else {
      label = 'Closed';
      color = 'default';
    }

    return (
      <Chip 
        label={label}
        color={color as 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'}
        size="small"
        sx={{ position: 'absolute', top: 16, right: 16 }}
      />
    );
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h2" gutterBottom>Open Opportunities</Typography>
      {strands.length === 0 ? (
        <Typography>No open opportunities available at the moment.</Typography>
      ) : (
        strands.map((strand) => {
          const remainingDays = getRemainingDays(strand.endDate);
          
          return (
            <Card key={strand._id} sx={{ mb: 3, position: 'relative' }}>
              <RemainingDaysChip days={remainingDays} />
              <CardContent>
                <Typography variant="h4">{strand.name}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {strand.type} | {new Date(strand.startDate).toLocaleDateString()} - {new Date(strand.endDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" paragraph>
                  {strand.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Chip 
                    label={strand.status === 'confirmed' ? "Open for Submissions" : "Closed"} 
                    color={strand.status === 'confirmed' ? "success" : "error"} 
                  />
                  {strand.status === 'confirmed' && (
                    <Chip 
                      label={`Deadline: ${new Date(strand.endDate).toLocaleDateString()}`} 
                      color="primary" 
                      sx={{ ml: 1 }} 
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => handleApply(strand)}
                    disabled={strand.status !== 'confirmed' || remainingDays <= 0}
                  >
                    Apply Now
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGuidelines(strand)}
                  >
                    Guidelines
                  </Button>
                </Box>
              </CardContent>
            </Card>
          );
        })
      )}
    </Container>
  );
};

export default PublicStrandsPage;