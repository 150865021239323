// src/components/jury/JuryApplicationList.tsx
import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Button,
  Box,
  AppBar,
  Toolbar,
  Container,
  CircularProgress,
  Alert
} from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useJuryAuth } from '../../contexts/JuryAuthContext';
import { BanBamApplication } from '../../types/jury';

const JuryApplicationList: React.FC = () => {
  const [applications, setApplications] = useState<BanBamApplication[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated, logout } = useJuryAuth();

  useEffect(() => {
    const fetchApplications = async () => {
      if (!isAuthenticated) return;
      try {
        const response = await axios.get<BanBamApplication[]>(
          `${process.env.REACT_APP_API_URL}/api/banbam`,
          { params: { status: 'jury' } }
        );
        
        // Sort alphabetically by last name
        const sortedApplications = [...response.data].sort((a, b) => 
          `${a.lastName}${a.firstName}`.localeCompare(`${b.lastName}${b.firstName}`)
        );
        
        setApplications(sortedApplications);
        setError(null);
      } catch (err) {
        setError('Failed to load applications');
        console.error('Error fetching applications:', err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchApplications();
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/jury/login" replace />;
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            BAN BAM Jury Portal
          </Typography>
          <Button color="inherit" onClick={() => logout()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Applications for Review
          </Typography>

          {loading ? (
            <Box display="flex" justifyContent="center" m={4}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : applications.length === 0 ? (
            <Typography>No applications currently assigned for jury review.</Typography>
          ) : (
            <List>
              {applications.map((application) => (
                <ListItem 
                  key={application._id}
                  divider
                  sx={{ py: 2 }}
                >
                  <ListItemText
                    primary={`${application.firstName} ${application.lastName}`}
                    secondary={
                      <>
                        <Typography component="span" variant="body2">
                          County: {application.county}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2">
                          Submitted: {new Date(application.createdAt).toLocaleDateString()}
                        </Typography>
                      </>
                    }
                  />
                  <Button
                    component={Link}
                    to={`/jury/applications/${application._id}`}
                    variant="contained"
                    sx={{ ml: 2 }}
                  >
                    Review Application
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default JuryApplicationList;