import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FormViewer from '../components/form/formviewer/FormViewer';
import { FormSchema } from '../types/FormSchema';
import axiosInstance, { handleApiError } from '../utils/axiosConfig';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  CircularProgress, 
  Alert, 
  AlertTitle,
  Paper
} from '@mui/material';
import PrePopulateCheckbox from '../components/form/PrePopulateCheckbox';

interface FormSubmissionResponse {
  message: string;
  submissionId?: string;
}

const DynamicFormPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>(); // Updated to use slug
  const navigate = useNavigate();
  
  // State management
  const [schema, setSchema] = useState<FormSchema | null>(null);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [shouldPrePopulate, setShouldPrePopulate] = useState(false);

  const handlePrePopulate = (profileData: any) => {
    console.log('Pre-populating with data:', profileData);
    // Create a new reference to trigger re-render
    const newValues = Object.assign({}, formValues, profileData);
    console.log('New form values after pre-populate:', newValues);
    setFormValues(newValues);
  };

  useEffect(() => {
    console.log('DynamicFormPage formValues updated:', formValues);
  }, [formValues]);

  // Fetch form schema
  useEffect(() => {
    const fetchFormSchema = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (!slug) {
          throw new Error('Form slug is required');
        }

        const response = await axiosInstance.get<FormSchema>(`/api/forms/${slug}`);
       setSchema(response.data);
      } catch (err) {
        const errorMessage = handleApiError(err);
        setError(`Failed to load form: ${errorMessage}`);
        console.error('Form schema fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFormSchema();
  }, [slug]);

  // Handle form field changes
  const handleChange = (fieldId: string, value: any) => {
    setFormValues(prev => ({
      ...prev,
      [fieldId]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!slug) {
      setSubmitError('Form slug is missing');
      return;
    }

    try {
      setSubmitting(true);
      setSubmitError(null);
      setSubmitSuccess(false);

      const response = await axiosInstance.post<FormSubmissionResponse>(
        `/api/forms/${slug}/submit`,
        formValues
      );

      setSubmitSuccess(true);
      setFormValues({}); // Clear form after successful submission

      // Optional: Redirect to success page or show success message
      setTimeout(() => {
        navigate(`/form-submission-success/${response.data.submissionId}`);
      }, 2000);

    } catch (err) {
      const errorMessage = handleApiError(err);
      setSubmitError(`Failed to submit form: ${errorMessage}`);
      console.error('Form submission error:', err);
    } finally {
      setSubmitting(false);
    }
  };

  // Loading state
  if (loading) {
    return (
      <Container maxWidth="md">
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="60vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Error state
  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 4 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Container>
    );
  }

  // No schema found state
  if (!schema) {
    return (
      <Container maxWidth="md">
        <Alert severity="warning" sx={{ mt: 4 }}>
          <AlertTitle>Form Not Found</AlertTitle>
          The requested form could not be found. Please check the URL and try again.
        </Alert>
      </Container>
    );
  }

  // Render form
  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 4, my: 4 }}>
        {submitSuccess && (
          <Alert severity="success" sx={{ mb: 4 }}>
            Form submitted successfully! Redirecting...
          </Alert>
        )}

        {submitError && (
          <Alert severity="error" sx={{ mb: 4 }}>
            <AlertTitle>Submission Error</AlertTitle>
            {submitError}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
        <PrePopulateCheckbox onPrePopulate={handlePrePopulate} />
          <FormViewer
            schema={schema}
            values={formValues}
            onChange={handleChange}
            disabled={submitting}
          />

          <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              type="button"
              variant="outlined"
              disabled={submitting}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitting}
              sx={{ minWidth: 120 }}
            >
              {submitting ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default DynamicFormPage;