import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,
  Typography,
  Box,
  Alert,
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/axiosConfig'; 

interface Employee {
  _id: string;
  name: string;
}

interface Expense {
  _id: string;
  date: string;
  staffId: string;
  category: string;
  amount: number;
  status: string;
  description: string;  // Add this
  location?: string;    // Ad
  // Include other fields as needed
}

const ExpenseListPage: React.FC = () => {
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [staffMembers, setStaffMembers] = useState<Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const expensesResponse = await axiosInstance.get<Expense[]>('/api/expenses');
        setExpenses(expensesResponse.data);
  
        const staffResponse = await axiosInstance.get<Employee[]>('/api/personnel');
        console.log('Staff Response:', staffResponse.data); // Add this log
        setStaffMembers(staffResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const staffMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    staffMembers.forEach(member => {
      map[member._id] = member.name;
    });
    console.log('Staff Map:', map); // Add this log
    console.log('Looking for staffId:', '66feffa2a52ff781904c27e8'); // Add this specific ID
    return map;
  }, [staffMembers]);

  const getEmployeeName = (staffId: string): string => {
    if (!staffId) return 'Unknown';
    return staffMap[staffId] || 'Unknown Employee';
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const handleDelete = async (expenseId: string) => {
    if (window.confirm('Are you sure you want to delete this expense?')) {
      try {
        await axiosInstance.delete(`/api/expenses/${expenseId}`);
        setExpenses(expenses.filter(expense => expense._id !== expenseId));
      } catch (err) {
        console.error('Error deleting expense:', err);
        setError('Failed to delete expense. Please try again later.');
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading expenses...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Expense List</Typography>
        <Button
          component={Link}
          to="/admin/expenses/new"          
          variant="contained"
          color="primary"
        >
          Add New Expense
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {expenses.length === 0 ? (
        <Alert severity="info">No expenses found. Add your first expense to get started.</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Staff Member</TableCell>
                <TableCell>Category</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.map((expense) => (
                <TableRow key={expense._id}>
                  <TableCell>
                  {new Date(expense.date).toLocaleDateString('en-IE', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})}
  {/* Add this temporarily for debugging */}
  <br/>
    <small style={{color: 'gray'}}>ID: {expense._id}</small>
    {expense.description && <br/>}
    <small style={{color: 'gray'}}>{expense.description}</small>
                  </TableCell>
                  <TableCell>{getEmployeeName(expense.staffId)}</TableCell>
                  <TableCell>{expense.category}</TableCell>
                  <TableCell align="right">{formatCurrency(expense.amount)}</TableCell>
                  <TableCell>
                    <Box
                      component="span"
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor:
                          expense.status.toLowerCase() === 'approved'
                            ? 'success.light'
                            : expense.status.toLowerCase() === 'pending'
                            ? 'warning.light'
                            : 'error.light',
                        color:
                          expense.status.toLowerCase() === 'approved'
                            ? 'success.dark'
                            : expense.status.toLowerCase() === 'pending'
                            ? 'warning.dark'
                            : 'error.dark',
                      }}
                    >
                      {expense.status.charAt(0).toUpperCase() + expense.status.slice(1)}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" gap={1}>
                      <Tooltip title="View Details">
                        <IconButton
                          component={Link}
                          to={`/admin/expenses/${expense._id}`}
                          size="small"
                          color="primary"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Expense">
                        <IconButton
                          component={Link}
                          to={`/admin/expenses/${expense._id}/edit`}
                          size="small"
                          color="secondary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Expense">
                        <IconButton
                          onClick={() => handleDelete(expense._id)}
                          size="small"
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ExpenseListPage;