import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@mui/material';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig'; 

interface Expense {
  _id: string;
  staffId: {
    _id: string;
    name: string;
  };
  category: string;
  businessName: string;
  amount: number;
  description: string;
  date: string;
  status: string;
  method: string;
  imcCode: string;
  vat: boolean;
  vatAmount?: number;
  receiptPhoto?: string;
}

const ExpenseDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [expense, setExpense] = useState<Expense | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    const fetchExpense = async () => {
      try {
        const response = await await axiosInstance.get<Expense>(`/api/expenses/${id}`);
        setExpense(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch expense details');
        setLoading(false);
      }
    };

    fetchExpense();
  }, [id]);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/api/expenses/${id}`);
      navigate('/admin/expenses');
    } catch (err) {
      setError('Failed to delete expense');
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!expense) return <Typography>No expense found</Typography>;

  return (
    <Paper style={{ padding: '20px', margin: '20px 0' }}>
      <Typography variant="h4" gutterBottom>Expense Detail</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Employee:</strong> {expense.staffId.name}</Typography>
          <Typography><strong>Category:</strong> {expense.category}</Typography>
          <Typography><strong>Business Name:</strong> {expense.businessName}</Typography>
          <Typography><strong>Amount:</strong> ${expense.amount.toFixed(2)}</Typography>
          <Typography><strong>Description:</strong> {expense.description}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Date:</strong> {new Date(expense.date).toLocaleDateString()}</Typography>
          <Typography><strong>Status:</strong> {expense.status}</Typography>
          <Typography><strong>Method:</strong> {expense.method}</Typography>
          <Typography><strong>IMC Code:</strong> {expense.imcCode}</Typography>
          <Typography><strong>VAT:</strong> {expense.vat ? 'Yes' : 'No'}</Typography>
          {expense.vat && <Typography><strong>VAT Amount:</strong> ${expense.vatAmount?.toFixed(2)}</Typography>}
        </Grid>
      </Grid>
      {expense.receiptPhoto && (
        <img src={expense.receiptPhoto} alt="Receipt" style={{ maxWidth: '100%', marginTop: '20px' }} />
      )}
      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={() => navigate(`/expenses/${id}/edit`)}>
          Edit
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(true)}>
          Delete
        </Button>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this expense? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ExpenseDetailPage;