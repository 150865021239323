// src/contexts/JuryAuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface JuryAuthContextType {
  isAuthenticated: boolean;
  login: (password: string) => void;
  logout: () => void;
}

const JuryAuthContext = createContext<JuryAuthContextType | null>(null);

export const JuryAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check local storage on mount
    const authStatus = localStorage.getItem('juryAuthenticated') === 'true';
    setIsAuthenticated(authStatus);
  }, []);

  const login = (password: string) => {
    // In production, this should be an environment variable
    const JURY_PASSWORD = "BanBam2024JuryAccess";
    
    if (password === JURY_PASSWORD) {
      setIsAuthenticated(true);
      localStorage.setItem('juryAuthenticated', 'true');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('juryAuthenticated');
  };

  return (
    <JuryAuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </JuryAuthContext.Provider>
  );
};

export const useJuryAuth = () => {
  const context = useContext(JuryAuthContext);
  if (!context) {
    throw new Error('useJuryAuth must be used within a JuryAuthProvider');
  }
  return context;
};