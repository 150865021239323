import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import FormViewer from '../formviewer/FormViewer';
import { FormField, FormSchema } from '../../../types/FormSchema';

interface FormPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  fields: FormField[];
  formTitle: string;
  formDescription: string;
}

const FormPreviewDialog: React.FC<FormPreviewDialogProps> = ({
  open,
  onClose,
  fields,
  formTitle,
  formDescription
}) => {
  const [previewValues, setPreviewValues] = useState<Record<string, any>>({});

  const previewSchema: FormSchema = {
    formId: `preview-${Date.now()}`,
    title: formTitle,
    description: formDescription,
    strand: 'preview',
    fields: fields
  };
  
  const handleFieldChange = (fieldId: string, value: any) => {
    setPreviewValues(prev => ({
      ...prev,
      [fieldId]: value
    }));
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<VisibilityIcon />}
        onClick={onClose} // This will toggle the open state in the parent
      >
        Preview Form
      </Button>
      
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Form Preview
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <FormViewer
            schema={previewSchema}
            values={previewValues}
            onChange={handleFieldChange}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormPreviewDialog;