import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#102E44',
    },
    secondary: {
      main: '#c2e5d5',
    },
    error: {
      main: '#ff6b6b',
    },
    success: {
      main: '#7bc043',
    },
    info: {
      main: '#3498db',
    },
    warning: {
      main: '#f39c12',
    },
  },
  typography: {
    fontFamily: "'CalculatorFont', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontFamily: "'CalculatorFont', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    },
  },
});

const CalcButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '60px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
}));

const OperationButton = styled(CalcButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const TipButton = styled(CalcButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const ClearButton = styled(CalcButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const EqualsButton = styled(CalcButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.common.white,
  height: '120px',
  '&:hover': {
    backgroundColor: theme.palette.info.dark,
  },
}));

const NumberButton = styled(CalcButton)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.warning.dark,
  },
}));

const Display = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  fontSize: '2.5rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
}));

interface ShowRunnrCalcProps {
  initialValue: number;
  onSubmit: (value: number) => void;
  onClose: () => void;
}

const ShowRunnrCalc: React.FC<ShowRunnrCalcProps> = ({ initialValue, onSubmit, onClose }) => {
  const [display, setDisplay] = useState(initialValue.toString());
  const [waitingForOperand, setWaitingForOperand] = useState(true);
  const [pendingOperator, setPendingOperator] = useState<string | null>(null);
  const [storedValue, setStoredValue] = useState<number | null>(null);
  const [currentOperator, setCurrentOperator] = useState<string | null>(null);

  useEffect(() => {
    setDisplay(initialValue.toString());
  }, [initialValue]);

  // ... [All your existing calculator logic functions remain the same]
  const inputDigit = (digit: string) => {
    if (waitingForOperand) {
      setDisplay(digit);
      setWaitingForOperand(false);
    } else {
      setDisplay(display === '0' ? digit : display + digit);
    }
  };

  const inputDot = () => {
    if (waitingForOperand) {
      setDisplay('0.');
      setWaitingForOperand(false);
    } else if (display.indexOf('.') === -1) {
      setDisplay(display + '.');
    }
  };

  const clearDisplay = () => {
    setDisplay('0');
    setWaitingForOperand(true);
    setPendingOperator(null);
    setStoredValue(null);
  };

  const performOperation = (operator: string) => {
    const inputValue = parseFloat(display);

    if (storedValue === null) {
      setStoredValue(inputValue);
    } else if (pendingOperator) {
      const newValue = calculate(storedValue, inputValue, pendingOperator);
      setStoredValue(newValue);
      setDisplay(newValue.toString());
    }

    setWaitingForOperand(true);
    setPendingOperator(operator);
    setCurrentOperator(operator);
  };

  const calculate = (x: number, y: number, operator: string): number => {
    switch (operator) {
      case '+': return x + y;
      case '-': return x - y;
      case '*': return x * y;
      case '/': return x / y;
      default: return y;
    }
  };

  const percentage = () => {
    const value = parseFloat(display);
    setDisplay((value / 100).toString());
  };

  const tip = (percentage: number) => {
    const value = parseFloat(display);
    setDisplay((value * (1 + percentage / 100)).toFixed(2));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        width: '100%', 
        maxWidth: 400, 
        p: 2, 
        backgroundColor: theme.palette.primary.main,
        margin: '0 auto'  // Center the calculator
      }}>
        <Typography
          variant="h4"
          color="secondary"
          sx={{ 
            textAlign: 'center', 
            marginBottom: 2,
            fontFamily: theme.typography.fontFamily
          }}
        >
          ShowRunnr Calc
        </Typography>

        <Display sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ color: 'grey.500' }}>
            {currentOperator}
          </Box>
          <Box>
            {display}
          </Box>
        </Display>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <ClearButton onClick={clearDisplay}>C</ClearButton>
          </Grid>
          <Grid item xs={3}>
            <TipButton onClick={() => tip(10)}>10% Tip</TipButton>
          </Grid>
          <Grid item xs={3}>
            <TipButton onClick={() => tip(15)}>15% Tip</TipButton>
          </Grid>
          <Grid item xs={3}>
            <TipButton onClick={() => tip(20)}>20% Tip</TipButton>
          </Grid>

          {/* Number pad grid */}
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('7')}>7</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('8')}>8</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('9')}>9</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <OperationButton onClick={() => performOperation('/')}>/</OperationButton>
          </Grid>

          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('4')}>4</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('5')}>5</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('6')}>6</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <OperationButton onClick={() => performOperation('*')}>×</OperationButton>
          </Grid>

          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('1')}>1</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('2')}>2</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('3')}>3</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <OperationButton onClick={() => performOperation('-')}>-</OperationButton>
          </Grid>

          <Grid item xs={3}>
            <NumberButton onClick={() => inputDigit('0')}>0</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <NumberButton onClick={inputDot}>.</NumberButton>
          </Grid>
          <Grid item xs={3}>
            <OperationButton onClick={percentage}>%</OperationButton>
          </Grid>
          <Grid item xs={3}>
            <OperationButton onClick={() => performOperation('+')}>+</OperationButton>
          </Grid>

          <Grid item xs={12}>
            <EqualsButton onClick={() => performOperation('=')}>=</EqualsButton>
          </Grid>

          <Grid item xs={6}>
            <Button 
              fullWidth 
              variant="outlined" 
              color="secondary" 
              onClick={onClose}
              sx={{ height: 48 }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
              fullWidth 
              variant="contained" 
              color="secondary" 
              onClick={() => onSubmit(parseFloat(display))}
              sx={{ height: 48 }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ShowRunnrCalc;