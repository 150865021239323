// dropdownOptions.ts

export interface DropdownOption {
  value: string;
  label: string;
}

export const dropdownOptions: DropdownOption[] = [
  { value: "2233155689", label: "Director" },
  { value: "1953902197", label: "For Review" },
  { value: "3868359858", label: "Better Live" },
  { value: "3996230132", label: "DCC March Event" },
  { value: "5873430916", label: "24 Administration" },
  { value: "5929889122", label: "Resources" },
  { value: "5929931052", label: "Music Alliance Ireland 2024" },
  { value: "5929931848", label: "Europe Jazz Network" },
  { value: "5929954077", label: "Irish Jazz Forum" },
  { value: "5929960524", label: "IMC Board" },
  { value: "5929972528", label: "Improvising Across Boundaries Research" },
  { value: "5960360285", label: "2024 Cooler CPD" },
  { value: "6488981469", label: "Cooler Build" },
  { value: "6616138149", label: "Conferences 2024" },
  { value: "6109572941", label: "Better Live Training" },
  { value: "5929819422", label: "Cooler Clinic Jan Online" },
  { value: "5153526703", label: "BAN BAM Premieres Dublin" },
  { value: "5929819921", label: "Cooler Clinic Feb" },
  { value: "5929820460", label: "Cooler Jam 5th Feb" },
  { value: "5929807054", label: "David Duffy - 1st Mar" },
  { value: "5929821403", label: "Cooler Clinic March online" },
  { value: "5929803860", label: "NOUT The Cooler - Better Live 24" },
  { value: "5929860859", label: "NOUT Workshop - Better Live 24" },
  { value: "5929860362", label: "NOUT Triskel - Better Live 24" },
  { value: "5929805918", label: "BAN BAM Tour 24" },
  { value: "5929809343", label: "Mikkel Ploug 24 Apr" },
  { value: "5929811095", label: "New Music Dub 24" },
  { value: "5929816234", label: "Aleka TBC" },
  { value: "5929886936", label: "Cooler Clinic April" },
  { value: "5929887276", label: "Cooler Clinic May" },
  { value: "5929887805", label: "Cooler Clinic June" },
  { value: "5929892388", label: "Navigator Letterkenny An Grianan" },
  { value: "5929932510", label: "Jazzahead 2024" },
  { value: "5929946138", label: "DCU Star Platform" },
  { value: "6109571119", label: "EJN Social Inclusion Seminar" },
  { value: "6557782309", label: "Aengus Hackett Trio" },
  { value: "7187463913", label: "Bianca Gannon Hugh Lane" },
  { value: "5929902412", label: "Navigator Garter Lane Waterford" },
  { value: "5929898165", label: "Navigator Belfast The Crescent" },
  { value: "5929851897", label: "12 Points Tampere 25-28 Sep" },
  { value: "5929918285", label: "Jazz Futures Tampere 25-28 Sep" },
  { value: "5929926058", label: "Fun Size Jazz 2024 - Sligo" },
  { value: "5929893519", label: "Navigator Tinahely Courthouse" },
  { value: "6811571988", label: "Better Live Meeting Lyon" },
  { value: "5929890966", label: "Navigator Ballina" },
  { value: "5929891365", label: "Navigator Thurles The Source" },
  { value: "5929906763", label: "Navigator Kildare Riverbank" },
  { value: "5929970325", label: "Jazz Camp for Girls - DCU" },
  { value: "5929939421", label: "Cooler Clinic Sept" },
  { value: "5929970811", label: "Jazz Camp for Girls - UCD" },
  { value: "5929971418", label: "Jazz Camp for Girls - CSM" },
  { value: "7180224373", label: "Córas Trio at The Cooler" },
  { value: "7180226662", label: "Nils Kavanagh Trio at The Cooler" },
  { value: "7180238477", label: "Cooler Clinic July" },
  { value: "7180238999", label: "Cooler Clinic August" },
  { value: "5929928052", label: "Filippo Vignato at The Cooler" },
  { value: "5929942950", label: "Cooler Clinic October" },
  { value: "5929952799", label: "Liv Andrea Hauge Trio Dublin" },
  { value: "5929943428", label: "Cooler Clinic November" },
  { value: "5929944116", label: "Cooler Clinic December" },
  { value: "6616139975", label: "JPN Conference" },
  { value: "7762197975", label: "AERIE at The Cooler" },
  { value: "1683885935", label: "30/30" },
  { value: "1683885960", label: "National Jazz Forum and Music Ireland Alliance" },
  { value: "1694153060", label: "IMC Website Development" },
  { value: "3618891126", label: "Navigator 2022" },
  { value: "1684910201", label: "Administration" },
  { value: "1684945035", label: "Europe Jazz Network" },
  { value: "1685096270", label: "Music Alliance Ireland" },
  { value: "1685339564", label: "IMC Board" },
  { value: "1694089969", label: "Fundraising RAISE" },
  { value: "1860499434", label: "Child Protection" },
  { value: "1860499738", label: "Charities Regulator" },
  { value: "3884347595", label: "Irish Jazz Forum" },
  { value: "3908525796", label: "Arts Council 70th Anniversary Event" },
  { value: "4131508205", label: "Incubator Filming in Phoenix Park" },
  { value: "4132848989", label: "Incubator Video Releases" },
  { value: "2138145216", label: "Zenith Award 2023" },
  { value: "1683885964", label: "Incubator - Creator Investment Initiative" },
  { value: "1683885969", label: "Write. Record. Perform. (in partnership with Triskel Arts Centre)" },
  { value: "2143548334", label: "Ban Bam Commission & Development 2023 (22)" },
  { value: "4132906586", label: "Cooler Clinic 2 - whats the deal with booking agents?" },
  { value: "3238754188", label: "Cooler Clinic 1" },
  { value: "3883175798", label: "30 - 30 Programme 2023" },
  { value: "3883214690", label: "Cooler Concerts 2023" },
  { value: "4131501659", label: "Pathways Trio at The Cooler" },
  { value: "3884316439", label: "Navigator - Ballina Arts" },
  { value: "4065891966", label: "EJN - AEP - Strasbourg" },
  { value: "4140327234", label: "Jazz Camp Training Day" },
  { value: "4140553820", label: "Ban Bam Screenings at New Music Dublin" },
  { value: "4140491850", label: "Bianca Gannon at Hugh Lane Gallery" },
  { value: "3883237732", label: "Jazzahead 2023" },
  { value: "4190256725", label: "Capacity Building Grant for Cooler" },
  { value: "4131507179", label: "Scott Flanigan at Arthurs" },
  { value: "4131506317", label: "Fixity at Arthurs" },
  { value: "4605518906", label: "Cooler Jazz Jam" },
  { value: "3884316829", label: "Navigator - Source Thurles" },
  { value: "4506550506", label: "Paul Dunlea Quintet" },
  { value: "3884317207", label: "Navigator - Grianan Donegal" },
  { value: "1683885915", label: "Jazz Camp for Girls - Dublin Edition" },
  { value: "3883227018", label: "Fun Size Jazz Cooler Programme 2023" },
  { value: "3884151804", label: "Irish Improvisers Orchestra 2023" },
  { value: "4256989353", label: "Cooler Build 2023" },
  { value: "4448394717", label: "EJN - Octopus Project - Schaffhausen" },
  { value: "4432795988", label: "EJN - Board Meeting - Bolzano" },
  { value: "3884862225", label: "The Cooler CPD 2023" },
  { value: "4997045907", label: "Cooler Jazz Jam August" },
  { value: "4971735278", label: "Paul Tynan & Limerick Jazz" },
  { value: "4997051676", label: "Culture Night" },
  { value: "1727625720", label: "Restless Islands Tour" },
  { value: "3883203240", label: "Better Live 2023 Programme" },
  { value: "3883245867", label: "Fun Size Jazz Schools Programme Riona 2023" },
  { value: "3884325259", label: "Navigator - Courthouse Tinahely" },
  { value: "3884325970", label: "Navigator - Crescent Belfast" },
  { value: "3884329774", label: "Navigator - Riverbank Kildare" },
  { value: "4680439634", label: "Musictown" },
  { value: "5499412864", label: "Navigator - Garter Lane Waterford" },
  { value: "3884330323", label: "Navigator - Garter Lane Waterford" },
  { value: "3883192936", label: "12 Points 2023" },
  { value: "5482681025", label: "Adjunct Ensemble 2023" },
  { value: "5531174523", label: "Lauren Kinsella Trio Hugh Lane Nov 23" },
  { value: "5599818884", label: "London Jazz Festival - Jazz from Ireland" },
  { value: "5669452281", label: "Jazzpool NRW" },
  { value: "3884340511", label: "BAN BAM Performances 2023" },
  { value: "5336622517", label: "Navigator - Tinahely Co. Wicklow" },
  { value: "5336626839", label: "Izumi Kimura / Gerry Hemingway" },
  { value: "5336632599", label: "Incubator Performance 23" },
  { value: "5336640918", label: "JPN Conference 23" },
  { value: "1918164864", label: "Afrobeat - Yankari & Selu" },
  { value: "1837476750", label: "Craig Taborn - Shadow Play - Solo Concert" },
  { value: "1767483802", label: "Joe O'Callaghan Quartet - February 17th" },
  { value: "1683885956", label: "Jazzahead International Tradefair and Showcase Platform" },
  { value: "1693039777", label: "European Jazz Conference (Sofia, Bulgaria)" },
  { value: "3573433310", label: "Christine Tobin - Returning Weather" },
  { value: "4185050284", label: "WRP Ajudication" },
  { value: "4471842046", label: "European Jazz Conference (Marseille, France)" },
  { value: "1683884189", label: "Jazz Connector CPD - Season 1" },
  { value: "1683885216", label: "12 Points Festival" },
  { value: "1683885947", label: "Young Irish Jazz Musician of the Year in assoc with Limerick Jazz Society and Festival" },
  { value: "1683885953", label: "Process Project - (Creative About Music Podcast, Int'l Jazz Day and more)" },
  { value: "1683885958", label: "The Space Programme" },
  { value: "1685096119", label: "Irish Jazz Forum" },
  { value: "1694089781", label: "Venue pencils, pricing, database" },
  { value: "1694095872", label: "Jazz Connector CPD - Season 2" },
  { value: "1694096439", label: "Jazz Connector CPD - Season 3" },
  { value: "1694097149", label: "Jazz Connector CPD - Season 4" },
  { value: "1694098536", label: "Navigator  2 - National Residencies and Performances Programme" },
  { value: "1694098730", label: "Navigator  3 - National Residencies and Performances Programme" },
  { value: "1694098924", label: "Navigator  4 - National Residencies and Performances Programme" },
  { value: "2029486812", label: "Capacity Building Grant" },
  { value: "2138259897", label: "Afrobeat - Manden & Elikya" },
  { value: "2151667303", label: "Navigator 2021" },
  { value: "2165079794", label: "Robson-Levy Quartet" },
  { value: "2483408743", label: "IAF Event" },
  { value: "2544330435", label: "Belgian Jazz Meeting" },
  { value: "2544363444", label: "EJN AEP Cully" },
  { value: "2629168863", label: "EJN - Beyond Europe - Sarajevo" },
  { value: "2629170773", label: "Criss Cross" },
  { value: "2683151298", label: "International Jazz Day 2022" },
  { value: "2719265061", label: "Down with Jazz" },
  { value: "2853603084", label: "Contra" },
  { value: "2998486047", label: "JPN Conference" },
  { value: "3238756426", label: "EJC - Sofia, Bulgaria" },
  { value: "3238767115", label: "30/30 - Emilie Conway" },
  { value: "3338135043", label: "EJN Board Meeting - Strasbourg" },
  { value: "3435449585", label: "Complex Jazz Mondays - NTE" },
  { value: "3435465065", label: "Camden Recording Studio" },
  { value: "3618882926", label: "Navigator 2022" },
  { value: "3618883730", label: "Navigator 2022" },
  { value: "5240965888", label: "EJC - Marseilles" },
  { value: "4333234860", label: "Jazz Camp for Girls 2023" },
  { value: "1756663403", label: "Fun-Size Jazz! - Sligo" },
  { value: "1641656790", label: "Solar Award" },
  { value: "1683885619", label: "Spectrum Festival" },
  { value: "1683885920", label: "Hotter Than July Festival" },
  { value: "1683885936", label: "Interland Series (Ghent and Oslo)" },
  { value: "1683885942", label: "Signal Series - 1" },
  { value: "1694145041", label: "Signal Series - 5" },
  { value: "1694145405", label: "Signal Series - 2" },
  { value: "1694145630", label: "Signal Series - 3" },
  { value: "1694145858", label: "Signal Series - 4" },
];