import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert,
  TableContainer,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/axiosConfig';

interface PopulatedStrand {
  _id: string;
  name: string;
}

interface PopulatedProspect {
  _id: string;
  eventConcept: string;
  date: string;
  status: string;
}

interface PopulatedEvent {
  _id: string;
  name: string;
}

interface PopulatedBand {
  _id: string;
  artistName?: string;
  name?: string;
}

interface PopulatedPerformance {
  _id: string;
  band: PopulatedBand | string;
}

interface PopulatedVenue {
  _id: string;
  name: string;
  capacity: number;
}

interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

interface TicketConfiguration {
  _id: string;
  selectionMethod: 'strand' | 'event';
  strandId?: PopulatedStrand;
  prospectId?: PopulatedProspect;
  eventId?: PopulatedEvent;
  performanceId?: PopulatedPerformance;
  venueId: PopulatedVenue;
  ticketTypes: TicketType[];
  createdAt: string;
  updatedAt: string;
}

export default function TicketConfigurationList() {
  const [configs, setConfigs] = useState<TicketConfiguration[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [configToDelete, setConfigToDelete] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const response = await axiosInstance.get<TicketConfiguration[]>('/api/ticket-configs');
      setConfigs(response.data);
    } catch (err: any) {
      setError(err.message || 'An error occurred while fetching ticket configurations.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (configId: string) => {
    setConfigToDelete(configId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (configToDelete) {
      try {
        await axiosInstance.delete(`/api/ticket-configs/${configToDelete}`);
        setConfigs(configs.filter(cfg => cfg._id !== configToDelete));
        setSuccessMessage('Configuration deleted successfully');
      } catch (error) {
        console.error('Error deleting configuration:', error);
        setError('Failed to delete configuration.');
      }
    }
    setDeleteDialogOpen(false);
    setConfigToDelete(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Ticket Configurations
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Showing {configs.length} configuration(s).
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Strand</TableCell>
              <TableCell>Prospect</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Performance</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell>Ticket Types</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configs.map((config) => {
              // Extract readable names
              const strandName = config.strandId ? config.strandId.name : 'N/A';
              const prospectName = config.prospectId 
                ? `${config.prospectId.eventConcept} (${new Date(config.prospectId.date).toLocaleDateString()})`
                : 'N/A';
              const eventName = config.eventId ? config.eventId.name : 'N/A';

              let performanceName = 'N/A';
              if (config.performanceId) {
                if (typeof config.performanceId.band === 'object') {
                  performanceName = config.performanceId.band.artistName || config.performanceId.band.name || 'Unnamed Band';
                } else {
                  performanceName = config.performanceId.band;
                }
              }

              const venueName = config.venueId ? config.venueId.name : 'N/A';

              return (
                <TableRow key={config._id}>
                  <TableCell>{strandName}</TableCell>
                  <TableCell>{prospectName}</TableCell>
                  <TableCell>{eventName}</TableCell>
                  <TableCell>{performanceName}</TableCell>
                  <TableCell>{venueName}</TableCell>
                  <TableCell>
                    {config.ticketTypes.map((tt, index) => (
                      <div key={index}>
                        <strong>{tt.name}</strong>: {tt.quantity} x €{tt.price}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{new Date(config.createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        component={Link}
                        to={`/ticket-configs/${config._id}`}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        View
                      </Button>
                      <IconButton
                        onClick={() => handleDeleteClick(config._id)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this configuration? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Success Message Snackbar */}
      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={3000} 
        onClose={() => setSuccessMessage(null)}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}