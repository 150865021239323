import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Box, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Autocomplete,
  Chip,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { IrishCounty } from '../../types/BandRecommendations';
import { instruments } from '../../constants/instrumentation';
import { Pronouns } from '../../types/Pronouns';
import jazzSubgenres from '../../data/jazzSubgenres';

interface Performer {
  name: string;
}

interface SelectedInstrument {
  id: string;
  name: string;
  withEffects: boolean;
  performer?: Performer;
}

const ArtistProfileEditPage = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    county: '',
    postalCode: '',
    phonePrefix: '',
    phoneNumber: '',
    bio: '',
    genres: [] as string[],
    website: '',
    facebookPage: '',
    twitterHandle: '',
    instagramHandle: '',
    tikTokHandle: '',
    instruments: [] as SelectedInstrument[],
    pronouns: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [openInstrumentDialog, setOpenInstrumentDialog] = useState(false);
  const [currentInstrument, setCurrentInstrument] = useState<SelectedInstrument | null>(null);

  // Safely flatten instruments array
  const flattenedInstruments = React.useMemo(() => {
    if (!Array.isArray(instruments)) return [];
    return instruments.reduce((acc, category) => {
      if (category && Array.isArray(category.options)) {
        return [...acc, ...category.options];
      }
      return acc;
    }, [] as { id: string; name: string }[]);
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }

      const data = await response.json();
      setProfileData({
        ...data,
        genres: data.genres || [],
        instruments: data.instruments || [],
      });
      setError(null);
    } catch (err) {
      setError('Failed to load profile data. Please try again later.');
      console.error('Error fetching profile data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setProfileData(prev => ({ ...prev, [name as string]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setProfileData(prev => ({ ...prev, [name]: value }));
  };

  const handleGenreChange = (event: React.SyntheticEvent, value: string[]) => {
    setProfileData(prev => ({ ...prev, genres: value.slice(0, 3) }));
  };

  const handleInstrumentSelect = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    if (value && profileData.instruments.length < 3) {
      setCurrentInstrument({
        id: value.id,
        name: value.name,
        withEffects: false,
      });
      setOpenInstrumentDialog(true);
    }
  };

  const handleAddInstrument = () => {
    if (currentInstrument) {
      setProfileData(prev => ({
        ...prev,
        instruments: [...prev.instruments, currentInstrument]
      }));
      setOpenInstrumentDialog(false);
      setCurrentInstrument(null);
    }
  };

  const handleRemoveInstrument = (id: string) => {
    setProfileData(prev => ({
      ...prev,
      instruments: prev.instruments.filter(instrument => instrument.id !== id)
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setSuccessMessage('Profile updated successfully!');
      setError(null);
    } catch (err) {
      setError('Failed to update profile. Please try again later.');
      console.error('Error updating profile:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Your Artist Profile
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Full Name"
                value={profileData.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Email Address"
                type="email"
                value={profileData.email}
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="streetAddress1"
                label="Street Address"
                value={profileData.streetAddress1}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="streetAddress2"
                label="Street Address Line 2"
                value={profileData.streetAddress2}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="city"
                label="City"
                value={profileData.city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>County</InputLabel>
                <Select
                  name="county"
                  value={profileData.county ?? ''}
                  onChange={handleSelectChange}
                  label="County"
                >
                  {Object.values(IrishCounty).map((county) => (
                    <MenuItem key={county} value={county}>{county}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="postalCode"
                label="Postal Code / Eircode"
                value={profileData.postalCode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                name="phonePrefix"
                label="Phone Prefix"
                value={profileData.phonePrefix}
                onChange={handleChange}
                inputProps={{ maxLength: 3 }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                value={profileData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="bio"
                label="Bio"
                value={profileData.bio}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="genres"
                options={jazzSubgenres}
                value={profileData.genres}
                onChange={handleGenreChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Jazz Sub-genres (Select up to 3)" fullWidth />
                )}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                  ))
                }
                limitTags={3}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Pronouns</InputLabel>
                <Select
                  name="pronouns"
                  value={profileData.pronouns}
                  onChange={handleSelectChange}
                  label="Pronouns"
                >
                  {Object.values(Pronouns).map((pronoun) => (
                    <MenuItem key={pronoun} value={pronoun}>{pronoun}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={flattenedInstruments}
                getOptionLabel={(option) => option.name}
                onChange={handleInstrumentSelect}
                disabled={profileData.instruments.length >= 3}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Instrument (max 3)"
                    fullWidth
                  />
                )}
              />
              <Box sx={{ mt: 2 }}>
                {profileData.instruments.map((instrument) => (
                  <Chip
                    key={instrument.id}
                    label={`${instrument.name}${instrument.withEffects ? ' (with effects)' : ''}${instrument.performer ? ` - ${instrument.performer.name}` : ''}`}
                    onDelete={() => handleRemoveInstrument(instrument.id)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="This is your primary website where all your information can be found, including links to your various bands. When you create a band page, you can have a separate main website for the band, but this is for you as an individual artist.">
                <TextField
                  fullWidth
                  name="website"
                  label="Main Website"
                  value={profileData.website}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="facebookPage"
                label="Facebook Page"
                value={profileData.facebookPage}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="twitterHandle"
                label="Twitter Handle"
                value={profileData.twitterHandle}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="instagramHandle"
                label="Instagram Handle"
                value={profileData.instagramHandle}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="tikTokHandle"
                label="TikTok Handle"
                value={profileData.tikTokHandle}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

        <Button type="submit" variant="contained" sx={{ mt: 3 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Profile'}
        </Button>
      </Box>

      <Dialog open={openInstrumentDialog} onClose={() => setOpenInstrumentDialog(false)}>
        <DialogTitle>Instrument Details</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentInstrument?.withEffects || false}
                  onChange={(e) => setCurrentInstrument(curr => curr ? {
                    ...curr,
                    withEffects: e.target.checked
                  } : null)}
                />
              }
              label="Uses effects"
            />
            <TextField
              fullWidth
              label="Performer Name"
              sx={{ mt: 2 }}
              value={currentInstrument?.performer?.name || ''}
              onChange={(e) => setCurrentInstrument(curr => curr ? {
                ...curr,
                performer: { name: e.target.value }
              } : null)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInstrumentDialog(false)}>Cancel</Button>
          <Button onClick={handleAddInstrument} variant="contained">Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ArtistProfileEditPage;