import React, { useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Youtube from '@tiptap/extension-youtube';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { 
  Box, 
  ToggleButton, 
  ToggleButtonGroup, 
  IconButton,
  Tooltip,
  Typography,
  Popover,
  Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button
} from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LinkIcon from '@mui/icons-material/Link';
import ImageIcon from '@mui/icons-material/Image';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import YouTubeIcon from '@mui/icons-material/YouTube';

interface RichTextEditorProps {
  content: string;
  onChange: (newContent: string) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
}

interface WordCount {
  characters: number;
  words: number;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ content, onChange }) => {
  const [wordCount, setWordCount] = useState<WordCount>({ characters: 0, words: 0 });
  const [colorPickerAnchor, setColorPickerAnchor] = useState<null | HTMLElement>(null);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [youtubeDialogOpen, setYoutubeDialogOpen] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');


  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Image,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Youtube.configure({
        controls: true,
        nocookie: true,
        progressBarColor: 'white',
      }),
      TextStyle,
      Color,
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
      updateWordCount(editor.getText());
    },
    editorProps: {
      attributes: {
        class: 'rich-text-editor',
        role: 'textbox',
        'aria-multiline': 'true',
        'aria-label': 'Rich text editor',
      },
    },
  });

  const updateWordCount = (text: string) => {
    const words = text.trim().split(/\s+/).filter(word => word.length > 0).length;
    const characters = text.length;
    setWordCount({ words, characters });
  };

// Modify the addImage function
const addImage = () => {
  setImageUrl('');
  setImageDialogOpen(true);
};

const handleImageInsert = () => {
  if (imageUrl && editor) {
    editor.chain().focus().setImage({ src: imageUrl }).run();
  }
  setImageDialogOpen(false);
};

  // Modify the setLink function
  const setLink = () => {
    if (!editor) return;
    const previousUrl = editor.getAttributes('link').href || '';
    setLinkUrl(previousUrl);
    setLinkDialogOpen(true);
  };
  
  // Function to handle inserting the link
  const handleLinkInsert = () => {
    if (linkUrl === '') {
      if (editor) {
        editor.chain().focus().unsetLink().run();
      }
    } else if (editor) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run();
    }
    setLinkDialogOpen(false);
  };
    
  const addChecklist = () => {
    editor?.chain().focus().toggleTaskList().run();
  };

// Modify the addYoutubeVideo function
const addYoutubeVideo = () => {
  setYoutubeUrl('');
  setYoutubeDialogOpen(true);
};

const handleYoutubeInsert = () => {
  if (youtubeUrl && editor) {
    editor.chain().focus().setYoutubeVideo({
      src: youtubeUrl,
      width: 640,
      height: 480,
    }).run();
  }
  setYoutubeDialogOpen(false);
};

  const handleColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setColorPickerAnchor(event.currentTarget);
  };

  const handleColorClose = () => {
    setColorPickerAnchor(null);
  };

  const handleColorChange = (color: string) => {
    if (editor) {
      editor.chain().focus().setColor(color).run();
      handleColorClose();
    }
  };

  const colors = [
    '#000000', '#FF0000', '#00FF00', '#0000FF',
    '#FF00FF', '#00FFFF', '#FFFF00', '#808080',
  ];

  if (!editor) {
    return null;
  }

  return (
    <Box 
      sx={{ 
        width: '100%',
        '& .rich-text-editor': {
          outline: 'none',
          '&:focus': {
            outline: 'none',
          }
        }
      }}
    >
      <Box mb={2} display="flex" flexWrap="wrap" gap={1}>
        <ToggleButtonGroup size="small" aria-label="text formatting">
          <ToggleButton 
            value="bold" 
            aria-label="bold" 
            onClick={() => editor.chain().focus().toggleBold().run()}
            selected={editor.isActive('bold')}
          >
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton 
            value="italic" 
            aria-label="italic" 
            onClick={() => editor.chain().focus().toggleItalic().run()}
            selected={editor.isActive('italic')}
          >
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton 
            value="underline" 
            aria-label="underline" 
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            selected={editor.isActive('underline')}
          >
            <FormatUnderlinedIcon />
          </ToggleButton>
          <ToggleButton 
            value="strike" 
            aria-label="strikethrough" 
            onClick={() => editor.chain().focus().toggleStrike().run()}
            selected={editor.isActive('strike')}
          >
            <StrikethroughSIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup size="small" aria-label="list formatting">
          <ToggleButton 
            value="bulletList" 
            aria-label="bullet list" 
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            selected={editor.isActive('bulletList')}
          >
            <FormatListBulletedIcon />
          </ToggleButton>
          <ToggleButton 
            value="orderedList" 
            aria-label="numbered list" 
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            selected={editor.isActive('orderedList')}
          >
            <FormatListNumberedIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup size="small" aria-label="text alignment">
          <ToggleButton 
            value="left" 
            aria-label="align left" 
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            selected={editor.isActive({ textAlign: 'left' })}
          >
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton 
            value="center" 
            aria-label="align center" 
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            selected={editor.isActive({ textAlign: 'center' })}
          >
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton 
            value="right" 
            aria-label="align right" 
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            selected={editor.isActive({ textAlign: 'right' })}
          >
            <FormatAlignRightIcon />
          </ToggleButton>
          <ToggleButton 
            value="justify" 
            aria-label="align justify" 
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            selected={editor.isActive({ textAlign: 'justify' })}
          >
            <FormatAlignJustifyIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <Box>
        <Tooltip title="Insert link">
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      setLink();
    }}
    size="small"
    aria-label="Insert link"
  >
    <LinkIcon />
  </IconButton>
</Tooltip>
          <Tooltip title="Insert image">
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      addImage();
    }}
    size="small"
    aria-label="Insert image"
  >
    <ImageIcon />
  </IconButton>
</Tooltip>
        </Box>

        <Box>
          <Tooltip title="Undo">
            <IconButton 
              onClick={() => editor.chain().focus().undo().run()} 
              size="small"
              aria-label="Undo"
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo">
            <IconButton 
              onClick={() => editor.chain().focus().redo().run()} 
              size="small"
              aria-label="Redo"
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Tooltip title="Add checklist">
            <IconButton 
              onClick={addChecklist} 
              size="small"
              aria-label="Add checklist"
            >
              <CheckBoxIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add YouTube video">
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      addYoutubeVideo();
    }}
    size="small"
    aria-label="Add YouTube video"
  >
    <YouTubeIcon />
  </IconButton>
</Tooltip>
          <Tooltip title="Text color">
            <IconButton 
              onClick={handleColorClick} 
              size="small"
              aria-label="Change text color"
            >
              <FormatColorTextIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Dialog open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)}>
      <DialogTitle>Insert Link</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="URL"
          type="url"
          fullWidth
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setLinkDialogOpen(false)}>Cancel</Button>
        <Button onClick={handleLinkInsert} color="primary">Insert</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
  <DialogTitle>Insert Image</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Image URL"
      type="url"
      fullWidth
      value={imageUrl}
      onChange={(e) => setImageUrl(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setImageDialogOpen(false)}>Cancel</Button>
    <Button onClick={handleImageInsert} color="primary">Insert</Button>
  </DialogActions>
</Dialog>
<Dialog open={youtubeDialogOpen} onClose={() => setYoutubeDialogOpen(false)}>
  <DialogTitle>Insert YouTube Video</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="YouTube URL"
      type="url"
      fullWidth
      value={youtubeUrl}
      onChange={(e) => setYoutubeUrl(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setYoutubeDialogOpen(false)}>Cancel</Button>
    <Button onClick={handleYoutubeInsert} color="primary">Insert</Button>
  </DialogActions>
</Dialog>

        <Popover
          open={Boolean(colorPickerAnchor)}
          anchorEl={colorPickerAnchor}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Paper sx={{ p: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {colors.map((color) => (
              <Box
                key={color}
                sx={{
                  width: 24,
                  height: 24,
                  backgroundColor: color,
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
                onClick={() => handleColorChange(color)}
                role="button"
                aria-label={`Select color ${color}`}
              />
            ))}
          </Paper>
        </Popover>
      </Box>

      <Box 
        sx={{ 
          border: '1px solid #ccc', 
          borderRadius: '4px', 
          padding: 2,
          minHeight: '200px',
        }}
      >
        <EditorContent editor={editor} />
      </Box>

      <Box mt={1} display="flex" justifyContent="flex-end">
        <Typography variant="body2" color="textSecondary">
          {wordCount.words} words | {wordCount.characters} characters
        </Typography>
      </Box>

      <style>{`
        .ProseMirror {
          > * + * {
            margin-top: 0.75em;
          }
          
          ul[data-type="taskList"] {
            list-style: none;
            padding: 0;

            li {
              display: flex;
              align-items: flex-start;

              > label {
                margin-right: 0.5rem;
                user-select: none;
              }

              > div {
                flex: 1;
              }
            }
          }

          iframe {
            max-width: 100%;
            max-height: 400px;
          }
        }
      `}</style>
    </Box>
  );
};

export default RichTextEditor;