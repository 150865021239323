import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { FormField } from '../../../types/FormSchema';
import FieldConfigModal from './FieldConfigModal';

interface CanvasFieldProps {
  field: FormField;
  onUpdate: (field: FormField) => void;
  onRemove: (fieldId: string) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const CanvasField: React.FC<CanvasFieldProps> = ({ field, onUpdate, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: field.id,
  });
  const [showConfig, setShowConfig] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const style: React.CSSProperties = {
    position: 'relative',
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    padding: '16px',
    backgroundColor: 'rgba(76, 86, 106, 0.4)',
    borderRadius: '8px',
    border: '1px solid rgba(136, 192, 208, 0.2)',
    cursor: 'move',
    color: '#fff',
    transition: 'all 0.2s ease',
    marginBottom: '8px'
  };

  const deleteButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isHovered ? 'rgba(191, 97, 106, 0.8)' : 'rgba(191, 97, 106, 0.4)',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    opacity: isHovered ? 1 : 0,
    transition: 'all 0.2s ease',
    padding: 0,
    fontSize: '14px'
  };

  const titleStyle: React.CSSProperties = {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '24px' // Make space for delete button
  };

  const typeStyle: React.CSSProperties = {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '12px',
    marginTop: '4px'
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      onClick={() => setShowConfig(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        style={deleteButtonStyle}
        onClick={(e) => {
          e.stopPropagation(); // Prevent opening config modal
          onRemove(field.id);
        }}
        title="Delete field"
      >
        ×
      </button>

      <div style={titleStyle}>
        {field.label || 'Untitled Field'}
      </div>
      <div style={typeStyle}>
        Type: {field.type}
      </div>

      {showConfig && (
        <FieldConfigModal
          field={field}
          onUpdate={(updatedField) => {
            onUpdate(updatedField);
            setShowConfig(false);
          }}
          onClose={() => setShowConfig(false)}
          onRemove={() => {
            onRemove(field.id);
            setShowConfig(false);
          }}
        />
      )}
    </div>
  );
};

export default CanvasField;