// src/pages/jury/ApplicationDetailPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Container, Box } from '@mui/material';
import { useJuryAuth } from '../../contexts/JuryAuthContext';
import BanBamApplicantDetail from '../../components/jury/BanBamApplicantDetail';
import { BanBamApplication } from '../../types/BanBam';

const ApplicationDetailPage: React.FC = () => {
  const [application, setApplication] = useState<BanBamApplication | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { isAuthenticated } = useJuryAuth();

  useEffect(() => {
    const fetchApplication = async () => {
      if (!isAuthenticated) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/banbam/${id}`);
        setApplication(response.data);
      } catch (error) {
        console.error('Error fetching application:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [id, isAuthenticated]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!application) {
    return <div>Application not found</div>;
  }

  return (
    <Container maxWidth="lg">
      <BanBamApplicantDetail application={application} />
    </Container>
  );
};

export default ApplicationDetailPage;