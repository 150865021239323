import React from 'react';
import { FormField, FieldType } from '../../../types/FormSchema';
import { 
  Modal, 
  TextField, 
  Button, 
  Checkbox, 
  Box, 
  Typography,
  Paper,
  styled,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Slider
} from '@mui/material';

interface FieldConfigModalProps {
  field: FormField;
  onUpdate: (field: FormField) => void;
  onClose: () => void;
  onRemove: () => void;
}

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgb(35, 40, 48)',
  border: '1px solid rgba(136, 192, 208, 0.2)',
  borderRadius: '8px',
  padding: '24px',
  width: '90%',
  maxWidth: '500px',
  maxHeight: '90vh',
  overflow: 'auto',
  '&:focus': {
    outline: 'none',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#fff',
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(136, 192, 208, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(136, 192, 208, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(143, 188, 187)',
    },
  },
});

const FieldConfigModal: React.FC<FieldConfigModalProps> = ({ 
  field, 
  onUpdate, 
  onClose, 
  onRemove 
}) => {
  // This is where the error was - the initialization was malformed
  const [localField, setLocalField] = React.useState<FormField>(() => {
    if (field.type === FieldType.Sig) {
      return {
        ...field,
        signatureConfig: {
          penColor: field.signatureConfig?.penColor || '#000000',
          penSize: field.signatureConfig?.penSize || 2,
          canvasHeight: field.signatureConfig?.canvasHeight || 200,
          backgroundColor: field.signatureConfig?.backgroundColor || '#ffffff',
          clearable: field.signatureConfig?.clearable ?? true,
        }
      };
    } else {
      return { ...field };
    }
  });


  const handleChange = (key: string, value: any) => {
    setLocalField((prevField) => ({ ...prevField, [key]: value }));
  };

  const handleSave = (event: React.MouseEvent) => {
    event.stopPropagation();
    onUpdate(localField);
    onClose();
  };

  const handleCancel = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove();
    onClose();
  };

  // User-friendly width options mapped to grid sizes
  const widthOptions = [
    { label: 'Full Width', value: 'full' },
    { label: 'Half Width', value: 'half' },
    { label: 'One Third', value: 'third' },
    { label: 'Quarter Width', value: 'quarter' },
  ];

  // Function to map user-friendly values to grid sizes
  const mapWidthToGrid = (widthValue: string) => {
    switch (widthValue) {
      case 'full':
        return { xs: 12 };
      case 'half':
        return { xs: 12, sm: 6 };
      case 'third':
        return { xs: 12, sm: 4 };
      case 'quarter':
        return { xs: 12, sm: 3 };
      default:
        return { xs: 12 };
    }
  };

  // Function to get current width value from grid settings
  const getWidthValue = () => {
    const grid = localField.grid;
    if (grid?.sm === 6) return 'half';
    if (grid?.sm === 4) return 'third';
    if (grid?.sm === 3) return 'quarter';
    return 'full';
  };

  // Handle width selection change
  const handleWidthChange = (value: string) => {
    const newGrid = mapWidthToGrid(value);
    setLocalField((prevField) => ({ ...prevField, grid: newGrid }));
  };

  const renderSignatureConfig = () => {
    if (localField.type !== FieldType.Sig) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
          Signature Configuration
        </Typography>
        
        <StyledTextField
          label="Pen Color"
          type="color"
          value={localField.signatureConfig?.penColor}
          onChange={(e) => handleChange('signatureConfig', {
            ...localField.signatureConfig,
            penColor: e.target.value
          })}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Typography sx={{ color: '#fff', mb: 1 }}>
          Pen Size: {localField.signatureConfig?.penSize}px
        </Typography>
        <Slider
          value={localField.signatureConfig?.penSize}
          onChange={(_, value) => handleChange('signatureConfig', {
            ...localField.signatureConfig,
            penSize: value
          })}
          min={1}
          max={5}
          step={0.5}
          sx={{
            color: 'rgb(143, 188, 187)',
            mb: 2
          }}
        />

        <StyledTextField
          label="Canvas Height (px)"
          type="number"
          value={localField.signatureConfig?.canvasHeight}
          onChange={(e) => handleChange('signatureConfig', {
            ...localField.signatureConfig,
            canvasHeight: Number(e.target.value)
          })}
          fullWidth
          sx={{ mb: 2 }}
        />

        <StyledTextField
          label="Background Color"
          type="color"
          value={localField.signatureConfig?.backgroundColor}
          onChange={(e) => handleChange('signatureConfig', {
            ...localField.signatureConfig,
            backgroundColor: e.target.value
          })}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Allow Clearing
          </Typography>
          <Checkbox
            checked={localField.signatureConfig?.clearable}
            onChange={(e) => handleChange('signatureConfig', {
              ...localField.signatureConfig,
              clearable: e.target.checked
            })}
            sx={{
              color: 'rgba(136, 192, 208, 0.5)',
              '&.Mui-checked': {
                color: 'rgb(143, 188, 187)',
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <StyledModal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        onClick: handleCancel
      }}
    >
      <StyledPaper onClick={(e) => e.stopPropagation()}>
        <Typography variant="h6" component="h2" sx={{ color: '#fff', mb: 3 }}>
          Edit Field
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <StyledTextField
            label="Label"
            value={localField.label}
            onChange={(e) => handleChange('label', e.target.value)}
            fullWidth
          />

          <StyledTextField
            label="Name"
            value={localField.name}
            onChange={(e) => handleChange('name', e.target.value)}
            fullWidth
          />

          {localField.type === FieldType.Select && (
            <StyledTextField
              label="Options (comma-separated)"
              value={(localField as any).options?.map((opt: any) => opt.label).join(', ')}
              onChange={(e) =>
                handleChange(
                  'options',
                  e.target.value.split(',').map((opt) => ({
                    value: opt.trim(),
                    label: opt.trim(),
                  }))
                )
              }
              fullWidth
              helperText="Enter options separated by commas"
            />
          )}

          {localField.type === FieldType.Upload && (
            <StyledTextField
              label="Accepted File Types"
              value={(localField as any).accept}
              onChange={(e) => handleChange('accept', e.target.value)}
              fullWidth
              helperText="Example: .pdf,.doc,.docx"
            />
          )}

          {localField.type === FieldType.Checkbox && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Default Value
              </Typography>
              <Checkbox
                checked={(localField as any).defaultValue}
                onChange={(e) => handleChange('defaultValue', e.target.checked)}
                sx={{
                  color: 'rgba(136, 192, 208, 0.5)',
                  '&.Mui-checked': {
                    color: 'rgb(143, 188, 187)',
                  },
                }}
              />
            </Box>
          )}

          {/* Width Selection */}
          <FormControl fullWidth>
            <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Field Width</InputLabel>
            <Select
              value={getWidthValue()}
              onChange={(e) => handleWidthChange(e.target.value)}
              label="Field Width"
              sx={{
                color: '#fff',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(136, 192, 208, 0.2)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(143, 188, 187)',
                },
                '.MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
            >
              {widthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {renderSignatureConfig()}

          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
            Field Type: {localField.type}
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            mt: 3,
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  bgcolor: 'rgb(143, 188, 187)',
                  color: 'rgb(35, 40, 48)',
                  '&:hover': {
                    bgcolor: 'rgb(143, 188, 187, 0.9)',
                  },
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  color: '#fff',
                  borderColor: 'rgba(136, 192, 208, 0.2)',
                  '&:hover': {
                    borderColor: 'rgba(136, 192, 208, 0.3)',
                    bgcolor: 'rgba(136, 192, 208, 0.1)',
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={handleDelete}
              sx={{
                bgcolor: 'rgb(191, 97, 106)',
                '&:hover': {
                  bgcolor: 'rgb(191, 97, 106, 0.9)',
                },
              }}
            >
              Delete Field
            </Button>
          </Box>
        </Box>
      </StyledPaper>
    </StyledModal>
  );
};

export default FieldConfigModal;