import { IMC_EXPENSE_CODES } from './imcCodes';
import { 
  Users, Mic2, Plane, Gift, HardDrive, Building, Wrench, 
  Computer, Receipt, FileText, Building2, Package, Phone, 
  Briefcase, Truck, GraduationCap, Music, ShoppingBag,
  CreditCard, Shield, Network, BarChart3, Lightbulb
} from 'lucide-react';

export interface ExpenseCategory {
  name: string;
  description: string;
  relatedIMCCodes: string[];
  icon: any;
  group: 'artists' | 'staff' | 'facilities' | 'technical' | 'operations' | 'professional' | 'finance';
  color?: string; // Add this line

}

export const EXPENSE_CATEGORIES: ExpenseCategory[] = [
  {
    name: "Artist Fees",
    description: "All direct payments to artists including fees and VAT",
    icon: Mic2,
    group: 'artists',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_FEES_INTERNATIONAL_JAZZ.code,          // 110
      IMC_EXPENSE_CODES.ARTISTS_FEES_INTERNATIONAL_OTHER.code,         // 111
      IMC_EXPENSE_CODES.ARTISTS_FEES_INTERNATIONAL_COLLABORATION.code, // 112
      IMC_EXPENSE_CODES.VAT_ON_ARTISTS_FEES.code,                     // 113
      IMC_EXPENSE_CODES.ARTISTS_FEES_DOMESTIC_JAZZ.code,              // 114
      IMC_EXPENSE_CODES.ARTISTS_FEES_DOMESTIC_OTHER.code,             // 115
      IMC_EXPENSE_CODES.DOMESTIC_ARTISTS_ENGAGEMENT.code,             // 154
      IMC_EXPENSE_CODES.ARTISTS_DEVELOPMENT_PROJECT_SUBVENTION.code   // 160
    ]
  },
  {
    name: "Artist Travel and Expenses",
    description: "All artist-specific travel, accommodation, and related expenses",
    icon: Plane,
    group: 'artists',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_INTERNATIONAL_TRAVEL.code,            // 116
      IMC_EXPENSE_CODES.ARTISTS_LOCAL_TRANSPORT.code,                 // 117
      IMC_EXPENSE_CODES.ARTISTS_ACCOMMODATION.code,                   // 118
      IMC_EXPENSE_CODES.ARTISTS_CATERING_HOSPITALITY.code,           // 119
      IMC_EXPENSE_CODES.ARTISTS_PER_DIEMS.code,                      // 120
      IMC_EXPENSE_CODES.ARTISTS_VISAS_WORK_PERMITS.code              // 121
    ]
  },
  {
    name: "Staff Travel and Expenses",
    description: "All staff-related travel, accommodation and subsistence",
    icon: Users,
    group: 'staff',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.STAFF_EXPENSES_TRAVEL.code,                  // 326
      IMC_EXPENSE_CODES.STAFF_EXPENSES_SUBSISTENCE.code,             // 327
      IMC_EXPENSE_CODES.STAFF_EXPENSES_POST_EVENTS.code,             // 328
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_TRAVEL.code,                // 137
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_SUBSISTENCE_PER_DIEMS.code, // 138
      IMC_EXPENSE_CODES.PRODUCTION_STAFF_ACCOMMODATION.code,         // 139
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_ACCOMMODATION.code,        // 315
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_TRAVEL.code,               // 316
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_SUBSISTENCE.code           // 317
    ]
  },
  {
    name: "Staff Benefits and Development",
    description: "Staff allowances, training, and benefits",
    icon: Gift,
    group: 'staff',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.STAFF_EXPENSES_WFH_ALLOWANCE.code,          // 323
      IMC_EXPENSE_CODES.STAFF_EXPENSES_RECRUITMENT.code,             // 324
      IMC_EXPENSE_CODES.STAFF_EXPENSES_TRAINING.code,               // 325
      IMC_EXPENSE_CODES.COMPANY_EXPENSES_GIFTS.code                 // 330
    ]
  },
  {
    name: "Production Personnel",
    description: "All production crew and technical staff expenses",
    icon: Users,
    group: 'staff',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.PRODUCTION_OTHER_CREW.code,                   // 126
      IMC_EXPENSE_CODES.PRODUCTION_FRONT_OF_HOUSE_CREW.code,         // 127
      IMC_EXPENSE_CODES.PRODUCTION_PA_ENGINEERS.code,                 // 131
      IMC_EXPENSE_CODES.PRODUCTION_LIGHTING_ENGINEERS.code,           // 134
      IMC_EXPENSE_CODES.PRODUCTION_STAGE_CREW.code,                  // 135
      IMC_EXPENSE_CODES.PRODUCTION_SPECIALIST_CREW.code,             // 147
      IMC_EXPENSE_CODES.PRODUCTION_PHOTOGRAPHY_VIDEOGRAPHY_PERSONNEL.code, // 149
      IMC_EXPENSE_CODES.PRODUCTION_EMP_SAFETY_OFFICERS.code          // 142
    ]
  },
  {
    name: "Building and Facilities",
    description: "All building-related expenses including rent, utilities, and maintenance",
    icon: Building,
    group: 'facilities',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.BUILDING_EXPENSES_RENT.code,                 // 351
      IMC_EXPENSE_CODES.BUILDING_EXPENSES_STORAGE.code,              // 352
      IMC_EXPENSE_CODES.BUILDING_EXPENSES_REPAIRS.code,              // 353
      IMC_EXPENSE_CODES.BUILDING_EXPENSES_UTILITIES.code,            // 354
      IMC_EXPENSE_CODES.BUILDING_EXPENSES_CLEANING.code,             // 355
      IMC_EXPENSE_CODES.VENUE_HIRE.code,                            // 143
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_CLEANING.code,              // 332
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_CANTEEN.code                // 339
    ]
  },
  {
    name: "Equipment and Technical",
    description: "Equipment purchase, rental, and maintenance",
    icon: Wrench,
    group: 'technical',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.PRODUCTION_PIANO_HIRE.code,                  // 128
      IMC_EXPENSE_CODES.PRODUCTION_BACKLINE_HIRE.code,               // 129
      IMC_EXPENSE_CODES.PRODUCTION_PA_HIRE.code,                     // 130
      IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_HIRE.code,              // 132
      IMC_EXPENSE_CODES.PRODUCTION_LIGHTING_HIRE.code,               // 133
      IMC_EXPENSE_CODES.PRODUCTION_PROPS_STAGING.code,               // 136
      IMC_EXPENSE_CODES.PRODUCTION_BACKLINE_TRANSPORT.code,          // 148
      IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_PURCHASES.code,         // 161
      IMC_EXPENSE_CODES.OTHER_EXPENSES_INSTRUMENT_BACKLINE_MAINTENANCE.code,  // 184
      IMC_EXPENSE_CODES.EQUIPMENT_EXPENSES_LEASING_VAN.code,         // 345-1
      IMC_EXPENSE_CODES.EQUIPMENT_EXPENSES_LEASING_2.code,           // 345-2
      IMC_EXPENSE_CODES.EQUIPMENT_EXPENSES_LEASING_3.code,           // 345-3
      IMC_EXPENSE_CODES.EQUIPMENT_MAINTENANCE.code,                  // 346
      IMC_EXPENSE_CODES.EQUIPMENT_FIXTURES.code,                     // 347
      IMC_EXPENSE_CODES.EQUIPMENT_DEPRECIATION.code,                 // 349
      IMC_EXPENSE_CODES.EQUIPMENT_DISPOSAL.code                      // 350
    ]
  },
  {
    name: "IT and Software",
    description: "All IT-related expenses including software subscriptions and cloud services",
    icon: Computer,
    group: 'technical',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.COMPANY_EXPENSES_IT_HOSTING.code,            // 344
      IMC_EXPENSE_CODES.IT_EXPENSES_GOOGLE.code,                     // 348-1
      IMC_EXPENSE_CODES.IT_EXPENSES_MONDAY.code,                     // 348-2
      IMC_EXPENSE_CODES.IT_EXPENSES_MIRO.code,                       // 348-3
      IMC_EXPENSE_CODES.IT_EXPENSES_EVERNOTE.code,                   // 348-4
      IMC_EXPENSE_CODES.IT_EXPENSES_JOTFORM.code,                    // 348-5
      IMC_EXPENSE_CODES.IT_EXPENSES_BUZZSPROUT.code,                 // 348-6
      IMC_EXPENSE_CODES.IT_EXPENSES_SURVEYMONKEY.code,               // 348-7
      IMC_EXPENSE_CODES.IT_EXPENSES_CALENDLY.code,                   // 348-8
      IMC_EXPENSE_CODES.IT_EXPENSES_SANEBOX.code,                    // 348-9
      IMC_EXPENSE_CODES.IT_EXPENSES_SKEDDA.code,                     // 348-10
      IMC_EXPENSE_CODES.IT_EXPENSES_MICROSOFT.code,                  // 348-11
      IMC_EXPENSE_CODES.IT_EXPENSES_DROPBOX.code,                    // 348-12
      IMC_EXPENSE_CODES.IT_EXPENSES_SETAPP.code,                     // 348-13
      IMC_EXPENSE_CODES.IT_EXPENSES_ZOOM.code                        // 348-14
    ]
  },
  {
    name: "Subscriptions and Media",
    description: "Media subscriptions and streaming services",
    icon: Music,
    group: 'operations',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.SUBSCRIPTION_APPLE_MUSIC.code,               // 361-1
      IMC_EXPENSE_CODES.SUBSCRIPTION_SPOTIFY.code,                   // 361-2
      IMC_EXPENSE_CODES.SUBSCRIPTION_AMAZON.code,                    // 361-3
      IMC_EXPENSE_CODES.SUBSCRIPTION_OTHER_1.code,                   // 361-4
      IMC_EXPENSE_CODES.SUBSCRIPTION_OTHER_2.code,                   // 361-5
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_PUBLICATIONS_IRISH_TIMES.code, // 340-1
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_PUBLICATIONS_OTHER.code      // 340-2
    ]
  },
  {
    name: "Marketing and Communications",
    description: "All marketing, advertising, and communication expenses",
    icon: FileText,
    group: 'operations',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.MARKETING_EXPENSES_GENERAL.code,             // 318
      IMC_EXPENSE_CODES.MARKETING_EXPENSES_WEB_DESIGN.code,          // 342-1
      IMC_EXPENSE_CODES.MARKETING_EXPENSES_VIMEO.code,               // 342-2
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_DESIGN_EXPENSES.code,    // 165
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_MAINTENANCE.code,        // 166
      IMC_EXPENSE_CODES.ADVERTISING_WEBSITE_HOSTING_DOMAINS.code,    // 167
      IMC_EXPENSE_CODES.MARKETING_DIGITAL_NEWS_LETTER.code,          // 179
      IMC_EXPENSE_CODES.MARKETING_ONLINE_ADVERTISING.code,           // 177
      IMC_EXPENSE_CODES.MARKETING_POSTER_ADVERTISING.code,           // 169
      IMC_EXPENSE_CODES.MARKETING_DESIGN.code,                       // 170
      IMC_EXPENSE_CODES.MARKETING_PRINT_MATERIALS.code,              // 171
      IMC_EXPENSE_CODES.MARKETING_POSTER_FLYER_DISTRIBUTION.code,    // 172
      IMC_EXPENSE_CODES.MARKETING_PHOTOGRAPHY.code,                  // 173
      IMC_EXPENSE_CODES.MARKETING_VIDEOGRAPHY.code,                  // 174
      IMC_EXPENSE_CODES.MARKETING_PUBLICATIONS.code,                 // 175
      IMC_EXPENSE_CODES.MARKETING_PR_ADVERTISING.code,               // 176
      IMC_EXPENSE_CODES.MARKETING_SUBSCRIPTIONS.code,                // 187
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_MARKETING.code,              // 336-3
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_COMMS.code                   // 336-6
    ]
  },
  {
    name: "Office Operations",
    description: "Day-to-day office operational expenses",
    icon: Building2,
    group: 'operations',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_STATIONERY.code,            // 331
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_COLOR_PRINTING.code,        // 333
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_LANDLINE.code,              // 334
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_POSTAGE.code,               // 337
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_COURIERS.code,              // 338
      IMC_EXPENSE_CODES.OFFICE_EXPENSES_SUNDRY.code,                // 341
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_CEO.code,                   // 336-1
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_GM.code,                    // 336-2
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_PRODUCTION.code,            // 336-4
      IMC_EXPENSE_CODES.MOBILE_EXPENSES_ARTIST_DEV.code,            // 336-5
      IMC_EXPENSE_CODES.PRODUCTION_COURIER_POSTAGE.code             // 140
    ]
  },
  {
    name: "Professional Services",
    description: "Legal, accounting, and professional consulting services",
    icon: Briefcase,
    group: 'professional',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_ACCOUNTING_SOFTWARE.code, // 357
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_CONSULTANTS.code,      // 360
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_LEGAL.code,           // 362
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_BOOKKEEPING.code,     // 363
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_AUDIT.code,           // 364
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_EJN.code,             // 365-1
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_JPN.code,             // 365-2
      IMC_EXPENSE_CODES.COMPANY_EXPENSES_INSURANCE.code             // 356
    ]
  },
  {
    name: "Vehicle and Transport",
    description: "Vehicle-related expenses",
    icon: Truck,
    group: 'operations',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.VEHICLE_EXPENSES.code,                      // 320
      IMC_EXPENSE_CODES.EQUIPMENT_EXPENSES_LEASING_VAN.code,        // 345-1
      IMC_EXPENSE_CODES.PRODUCTION_BACKLINE_TRANSPORT.code          // 148
    ]
  },
  {
    name: "Governance and Board",
    description: "Board-related expenses and governance costs",
    icon: GraduationCap,
    group: 'professional',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.COMPANY_EXPENSES_BOARD.code,                // 319
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_ACCOMMODATION.code,       // 315
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_TRAVEL.code,              // 316
      IMC_EXPENSE_CODES.DIRECTOR_EXPENSES_SUBSISTENCE.code,         // 317
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_AUDIT.code            // 364
    ]
  },
  {
    name: "Recording and Audio",
    description: "Recording-related personnel and equipment expenses",
    icon: Music,
    group: 'technical',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.RECORDINGS_EXPENSES_ARTISTS_RECORDING_FEES.code,    // 150
      IMC_EXPENSE_CODES.RECORDING_EXPENSES_STUDIO_RENTAL.code,             // 151
      IMC_EXPENSE_CODES.RECORDING_EXPENSES_RECORDING_TECHNICIANS.code,     // 152
      IMC_EXPENSE_CODES.RECORDING_EXPENSES_DESIGN.code,                    // 153
      IMC_EXPENSE_CODES.SUBSCRIPTION_APPLE_MUSIC.code,                     // 361-1
      IMC_EXPENSE_CODES.SUBSCRIPTION_SPOTIFY.code                          // 361-2
    ]
  },
  {
    name: "Merchandise",
    description: "All merchandise-related expenses",
    icon: ShoppingBag,
    group: 'operations',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.ARTISTS_MERCHANDISE.code,                   // 122
      IMC_EXPENSE_CODES.MARKETING_MERCHANDISE.code                  // 178
    ]
  },
  {
    name: "Financial and Banking",
    description: "Banking charges, financial adjustments, and accounting",
    icon: CreditCard,
    group: 'finance',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.BANK_CHARGES.code,                         // 371
      IMC_EXPENSE_CODES.BAD_DEBTS.code,                           // 373
      IMC_EXPENSE_CODES.SMALL_DIFFERENCES.code,                    // 366
      IMC_EXPENSE_CODES.DONATIONS.code,                           // 374
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_BOOKKEEPING.code,    // 363
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_ACCOUNTING_SOFTWARE.code  // 357
    ]
  },
  {
    name: "Insurance and Compliance",
    description: "Insurance, legal compliance, and regulatory expenses",
    icon: Shield,
    group: 'finance',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.COMPANY_EXPENSES_INSURANCE.code,           // 356
      IMC_EXPENSE_CODES.IMRO_PUBLIC_LIABILITY_INSURANCE.code,      // 145
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_LEGAL.code,          // 362
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_AUDIT.code           // 364
    ]
  },
  {
    name: "Memberships and Partnerships",
    description: "Professional memberships and partnership expenses",
    icon: Network,
    group: 'professional',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_EJN.code,            // 365-1
      IMC_EXPENSE_CODES.PROFESSIONAL_EXPENSES_JPN.code,            // 365-2
      IMC_EXPENSE_CODES.EVENT_COLLABORATION_REIMBURSEMENTS.code,   // 125
      IMC_EXPENSE_CODES.OTHER_EXPENSES_NATIONAL_CAMPAIGN_FOR_THE_ARTS.code  // 181
    ]
  },
  {
    name: "Depreciation and Asset Management",
    description: "Asset depreciation and disposal",
    icon: BarChart3,
    group: 'finance',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.EQUIPMENT_DEPRECIATION.code,               // 349
      IMC_EXPENSE_CODES.EQUIPMENT_DISPOSAL.code,                   // 350
      IMC_EXPENSE_CODES.EQUIPMENT_MAINTENANCE.code,                // 346
      IMC_EXPENSE_CODES.EQUIPMENT_FIXTURES.code                    // 347
    ]
  },
  {
    name: "Special Projects and Research",
    description: "Special initiatives, research, and development projects",
    icon: Lightbulb,
    group: 'professional',
    relatedIMCCodes: [
      IMC_EXPENSE_CODES.OTHER_EXPENSES_RESEARCH.code,              // 182
      IMC_EXPENSE_CODES.OTHER_EXPENSES_SPONSORSHIP_FUNDRAISING_EXPENSES.code, // 183
      IMC_EXPENSE_CODES.OTHER_EXPENSES_IN_KIND_CONTRA.code,        // 180
      IMC_EXPENSE_CODES.EVENT_COLLABORATION_REIMBURSEMENTS.code,   // 125
      IMC_EXPENSE_CODES.OTHER_EXPENSES_NATIONAL_CAMPAIGN_FOR_THE_ARTS.code  // 181
    ]
  }
];

export const CATEGORY_GROUPS = {
  artists: "Artists & Performers",
  staff: "Staff & Personnel",
  facilities: "Buildings & Facilities",
  technical: "Technical & Equipment",
  operations: "Operations",
  professional: "Professional Services",
  finance: "Finance & Governance"
};

// Helper functions remain unchanged
export const getCategoryByName = (name: string): ExpenseCategory | undefined => {
  return EXPENSE_CATEGORIES.find(category => category.name === name);
};

export const getRelatedIMCCodes = (categoryName: string): string[] => {
  const category = getCategoryByName(categoryName);
  return category ? category.relatedIMCCodes : [];
};

export const getIMCCodeCategory = (code: string): ExpenseCategory[] => {
  return EXPENSE_CATEGORIES.filter(category => 
    category.relatedIMCCodes.includes(code)
  );
};



export const getRelatedIMCLabels = (categoryName: string): string[] => {
  const category = getCategoryByName(categoryName);
  if (!category) return [];
  
  return category.relatedIMCCodes.map(code => {
    const codeObj = Object.values(IMC_EXPENSE_CODES)
      .find(item => item.code === code);
    return codeObj?.label || code;
  });
};

export const verifyAllCodesAreMapped = (): {
  mapped: { code: string, categories: string[] }[],
  unmapped: string[]
} => {
  const allCodes = Object.values(IMC_EXPENSE_CODES).map(code => code.code);
  const mappedCodesWithCategories = allCodes.map(code => ({
    code,
    categories: EXPENSE_CATEGORIES
      .filter(cat => cat.relatedIMCCodes.includes(code))
      .map(cat => cat.name)
  }));
  const unmappedCodes = mappedCodesWithCategories
    .filter(item => item.categories.length === 0)
    .map(item => item.code);
  const mappedCodes = mappedCodesWithCategories
    .filter(item => item.categories.length > 0);
    
  return {
    mapped: mappedCodes,
    unmapped: unmappedCodes
  };
};