import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MusicNote, Event, School, Edit, Delete, Visibility, AutoStories } from '@mui/icons-material';
import { 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  Box, 
  Card, 
  CardContent, 
  CardMedia,
  CardActions,
  List, 
  ListItem, 
  ListItemText, 
  Chip,
  CircularProgress,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import axiosInstance from '../../utils/axiosConfig';
import AvailableOpportunities from '../../components/AvailableOpportunities';
import { NavigatorFormData } from '../../types/NavigatorTypes';

interface UpcomingEvent {
  _id: string;
  name: string;
  date: string;
  focus: string;
}

interface Band {
  _id: string;
  artistName: string;
  mainImage?: string;
}

interface Application {
  _id: string;
  type: 'navigator' | 'other-opportunity-types';
  status: 'draft' | 'submitted';
  lastSaved?: Date;
  submittedAt?: Date;
  deadline: Date;
  formData: NavigatorFormData;
}

interface StagePlot {
  _id: string;
  name: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

const ArtistDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEvent[]>([]);
  const [bands, setBands] = useState<Band[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bandToDelete, setBandToDelete] = useState<Band | null>(null);
  const [applications, setApplications] = useState<Application[]>([]);
  const [stagePlots, setStagePlots] = useState<StagePlot[]>([]);


  const recentActivities = [
    { id: 1, action: 'Updated profile', date: '2023-06-30' },
    { id: 2, action: 'Submitted application', date: '2023-06-28' },
  ];

  // Fetch upcoming events
  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      try {
        const response = await axiosInstance.get<UpcomingEvent[]>('/api/events/upcoming');
        setUpcomingEvents(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching upcoming events:', error);
        setError('Failed to load upcoming events');
      }
    };

    fetchUpcomingEvents();
  }, []);

  // Fetch bands
  useEffect(() => {
    const fetchBands = async (userId: string) => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<Band[]>(`/api/users/${userId}/bands`);
        setBands(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching bands:', error);
        setError('Failed to load bands');
      } finally {
        setLoading(false);
      }
    };
  
    if (user?._id) {
      fetchBands(user._id);
    }
  }, [user]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axiosInstance.get('/api/applications/drafts');
        const drafts = response.data.map((draft: any) => ({ 
          ...draft, 
          status: 'draft' 
        }));
        setApplications(drafts);
      } catch (error) {
        console.error('Error fetching applications:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchApplications();
  }, []);

  useEffect(() => {
    const fetchStagePlots = async () => {
      try {
        const response = await axiosInstance.get('/api/stageplots');
        setStagePlots(response.data);
      } catch (error) {
        console.error('Error fetching stage plots:', error);
      }
    };
  
    fetchStagePlots();
  }, []);
  

  const handleDeleteClick = (band: Band) => {
    setBandToDelete(band);
    setDeleteDialogOpen(true);
  };

  const handleDeleteStagePlot = async (plotId: string) => {
    if (window.confirm('Are you sure you want to delete this stage plot?')) {
      try {
        await axiosInstance.delete(`/api/stageplots/${plotId}`);
        setStagePlots(plots => plots.filter(plot => plot._id !== plotId));
      } catch (error) {
        console.error('Error deleting stage plot:', error);
      }
    }
  };

  const handleDeleteConfirm = async () => {
    if (!bandToDelete) return;

    try {
      await axiosInstance.delete(`/api/bandrecommendations/${bandToDelete._id}`);
      setBands(bands.filter(band => band._id !== bandToDelete._id));
      setDeleteDialogOpen(false);
      setBandToDelete(null);
    } catch (error) {
      console.error('Error deleting band:', error);
      setError('Failed to delete band');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Artist Dashboard
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
  
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={8}>
          {/* Profile Section */}
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Your Profile
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button 
                component={RouterLink} 
                to="/artist/profile" 
                variant="contained"
              >
                Edit Profile
              </Button>
              <Button 
                component={RouterLink} 
                to="/artist/portfolio" 
                variant="outlined"
              >
                View Portfolio
              </Button>
            </Box>
          </Paper>
  
          {/* Applications Section - Moved up */}
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Your Applications
            </Typography>
            <Box sx={{ mt: 2 }}>
              {loading ? (
                <CircularProgress size={24} />
              ) : applications.length > 0 ? (
                <List>
                  {applications.map((application) => (
                    <ListItem
                      key={application._id}
                      sx={{ 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        mb: 1 
                      }}
                    >
                      <ListItemText
                        primary={`${application.type.charAt(0).toUpperCase() + application.type.slice(1)} Application`}
                        secondary={
                          <>
                            Status: {application.status.charAt(0).toUpperCase() + application.status.slice(1)}
                            <br />
                            {application.status === 'draft' ? 
                              `Last saved: ${new Date(application.lastSaved!).toLocaleDateString()}` :
                              `Submitted: ${new Date(application.submittedAt!).toLocaleDateString()}`}
                            <br />
                            Deadline: {new Date(application.deadline).toLocaleDateString()}
                          </>
                        }
                      />
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {application.status === 'draft' && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => {/* Your delete handler */}}
                          >
                            Delete
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          size="small"
                          component={RouterLink}
                          to={`/${application.type}/apply${application.status === 'draft' ? `?draft=${application._id}` : ''}`}
                        >
                          {application.status === 'draft' ? 'Continue' : 'View'}
                        </Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1">
                  You haven't submitted any applications yet.
                </Typography>
              )}
            </Box>
          </Paper>
  
          {/* Bands and Stage Plots Section */}
          <Paper sx={{ p: 2 }}>
            <Box sx={{ mb: 3 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                  Your Bands
                </Typography>
                <Button 
                  component={RouterLink} 
                  to="/submit" 
                  variant="contained" 
                  startIcon={<MusicNote />}
                >
                  Create Band
                </Button>
              </Box>
              
              <Grid container spacing={2}>
                {bands.map((band) => (
                  <Grid item xs={12} sm={6} key={band._id}>
                    <Card>
                      <Box sx={{ display: 'flex' }}>
                        {band.mainImage && (
                          <CardMedia
                            component="img"
                            sx={{ width: 120, height: 120, objectFit: 'cover' }}
                            image={band.mainImage}
                            alt={band.artistName}
                          />
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="h6">
                              {band.artistName}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <IconButton size="small" onClick={() => navigate(`/band/${band._id}`)}>
                              <Visibility />
                            </IconButton>
                            <IconButton size="small" onClick={() => navigate(`/submit?edit=${band._id}`)}>
                              <Edit />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleDeleteClick(band)}>
                              <Delete />
                            </IconButton>
                          </CardActions>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
  
            {/* Stage Plots */}
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                  Stage Plots
                </Typography>
                <Button 
                  component={RouterLink} 
                  to="/artist/stage-plot" 
                  variant="contained" 
                  startIcon={<AutoStories />}
                >
                  Create New Stage Plot
                </Button>
              </Box>
              
              <Grid container spacing={2}>
                {stagePlots.map((plot) => (
                  <Grid item xs={12} sm={6} key={plot._id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {plot.name}
                        </Typography>
                        {plot.description && (
                          <Typography variant="body2" color="text.secondary">
                            {plot.description}
                          </Typography>
                        )}
                        <Typography variant="caption" color="text.secondary">
                          Last updated: {new Date(plot.updatedAt).toLocaleDateString()}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <IconButton size="small" onClick={() => navigate(`/artist/stage-plot/${plot._id}`)}>
                          <Visibility />
                        </IconButton>
                        <IconButton size="small" onClick={() => navigate(`/artist/stage-plot/${plot._id}/edit`)}>
                          <Edit />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteStagePlot(plot._id)}>
                          <Delete />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
  
        {/* Right Column */}
        <Grid item xs={12} md={4}>
          {/* Upcoming Events */}
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Upcoming Events
            </Typography>
            <List>
              {upcomingEvents.map((event) => (
                <ListItem key={event._id}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        {event.name}
                        {event.focus === 'Artist' && (
                          <Chip label="For Artists" color="primary" size="small" sx={{ ml: 1 }} />
                        )}
                      </Box>
                    }
                    secondary={new Date(event.date).toLocaleDateString()}
                  />
                </ListItem>
              ))}
            </List>
            <Button 
              component={RouterLink} 
              to="/artist/events" 
              variant="outlined" 
              startIcon={<Event />}
              fullWidth
            >
              View All Events
            </Button>
          </Paper>
  
          {/* Recent Activity */}
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <List>
              {recentActivities.map((activity) => (
                <ListItem key={activity.id}>
                  <ListItemText 
                    primary={activity.action} 
                    secondary={activity.date} 
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
  
        {/* Full Width Bottom Sections */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Available Opportunities
            </Typography>
            <Box sx={{ mt: 2 }}>
              <AvailableOpportunities />
            </Box>
          </Paper>
        </Grid>
  
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Learning Resources
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button 
                component={RouterLink} 
                to="/artist/workshops" 
                variant="outlined" 
                startIcon={<School />}
              >
                Available Workshops
              </Button>
              <Button 
                component={RouterLink} 
                to="/artist/resources" 
                variant="outlined"
              >
                Artist Resources
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
  
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Band</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {bandToDelete?.artistName}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ArtistDashboard;