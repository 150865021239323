// src/pages/GuidelineViewPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from '../utils/axiosConfig';
import { useAuth } from '../contexts/AuthContext';

// Import the display components
import AccordionDisplay from '../components/guidelines/AccordionDisplay';
import TimelineDisplay from '../components/guidelines/TimelineDisplay';
import ContactInfoDisplay from '../components/guidelines/ContactInfoDisplay';

// Keep all your existing interfaces...
interface ContentSection {
  id: string;
  title: string;
  content: string;
}

interface TimelineEvent {
  id: string;
  title: string;
  date: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: string;
}

interface ContactInfo {
  id: string;
  type: string;
  value: string;
  link?: string;
}

interface GuidelineVersion {
  title: string;
  subtitle?: string;
  contentSections: ContentSection[];
  timeline: TimelineEvent[];
  contactInfo: ContactInfo[];
  createdAt?: Date;
  createdBy?: string;
}

interface Strand {
  _id: string;
  name: string;
  slug: string;
}

interface Guideline {
  _id?: string;
  strandId: string | Strand;
  currentVersion: number;
  versions: GuidelineVersion[];
  isPublished: boolean;
  publishedVersion?: number;
  publishedAt?: Date;
  applicationLink?: string;
  deadline?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

const GuidelineViewPage: React.FC = () => {
  const { strandSlug } = useParams<{ strandSlug: string }>();
  const [searchParams] = useSearchParams();
  const requestedVersion = searchParams.get('version');
  const [guideline, setGuideline] = useState<Guideline | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchGuideline = async () => {
      if (!strandSlug) return;
      
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/api/guidelines/strand/${strandSlug}`);
        setGuideline(response.data);
      } catch (err: any) {
        console.error('Error fetching guideline:', err);
        setError(err.response?.data?.message || 'An error occurred while fetching guidelines');
      } finally {
        setLoading(false);
      }
    };

    fetchGuideline();
  }, [strandSlug]);

  const handleEdit = () => {
    if (guideline?._id) {
      navigate(`/guidelines/${guideline._id}/edit`);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box my={4}>
          <Typography variant="h4" color="error" gutterBottom>
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Go Back
          </Button>
        </Box>
      </Container>
    );
  }

  if (!guideline || !guideline.versions || guideline.versions.length === 0) {
    return (
      <Container>
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            No guidelines available
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Go Back
          </Button>
        </Box>
      </Container>
    );
  }

  // Get the requested version, published version, or latest version
  let currentVersion;
  if (requestedVersion) {
    // If version is specified in URL, use that
    currentVersion = guideline.versions[0];
  } else if (guideline.isPublished && guideline.publishedVersion) {
    // If no version specified but guideline is published, use published version
    currentVersion = guideline.versions[0];
  } else {
    // Otherwise use the latest version
    currentVersion = guideline.versions[0];
  }

  if (!currentVersion) {
    return (
      <Container>
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Guidelines version not found
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Go Back
          </Button>
        </Box>
      </Container>
    );
  }

  const strandName = typeof guideline.strandId === 'object' && 'name' in guideline.strandId
    ? guideline.strandId.name
    : 'Unknown Strand';

  // Rest of your render code stays exactly the same...
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h3">
            {strandName}
          </Typography>
          {user?.role === 'admin' && guideline._id && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleEdit}
            >
              Edit Guidelines
            </Button>
          )}
        </Box>

        <Typography variant="h4" gutterBottom>
          {currentVersion.title}
        </Typography>
        {currentVersion.subtitle && (
          <Typography variant="h5" gutterBottom>
            {currentVersion.subtitle}
          </Typography>
        )}
        {guideline.applicationLink && (
          <Box my={2}>
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={guideline.applicationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Now
            </Button>
          </Box>
        )}
        {currentVersion.contentSections && currentVersion.contentSections.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Information
            </Typography>
            <Divider />
            <AccordionDisplay sections={currentVersion.contentSections} />
          </Box>
        )}
        {currentVersion.timeline && currentVersion.timeline.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Timeline
            </Typography>
            <Divider />
            <TimelineDisplay events={currentVersion.timeline} />
          </Box>
        )}
        {currentVersion.contactInfo && currentVersion.contactInfo.length > 0 && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Contact Information
            </Typography>
            <Divider />
            <ContactInfoDisplay contactInfo={currentVersion.contactInfo} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default GuidelineViewPage;