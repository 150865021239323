import React, { useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { FormField } from '../../../types/FormSchema';
import CanvasField from './CanvasField';
import { Grid, IconButton, Box, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface FormCanvasProps {
  fields: FormField[];
  onUpdateField: (field: FormField) => void;
  onRemoveField: (fieldId: string) => void;
  onReorderFields?: (newFields: FormField[]) => void;
}

const FormCanvas: React.FC<FormCanvasProps> = ({ 
  fields, 
  onUpdateField, 
  onRemoveField,
  onReorderFields
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: 'canvas',
  });

  const moveField = (fieldId: string, direction: 'up' | 'down') => {
    if (!onReorderFields) return;
    
    const currentIndex = fields.findIndex(field => field.id === fieldId);
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    
    if (newIndex >= 0 && newIndex < fields.length) {
      const newFields = [...fields];
      const temp = newFields[currentIndex];
      newFields[currentIndex] = newFields[newIndex];
      newFields[newIndex] = temp;
      onReorderFields(newFields);

      // Add visual feedback animation
      const element = document.querySelector(`[data-field-id="${fieldId}"]`);
      element?.classList.add('field-moved');
      setTimeout(() => {
        element?.classList.remove('field-moved');
      }, 300);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (!activeElement?.closest('.field-container')) return;
      
      const fieldId = activeElement.closest('.field-container')?.getAttribute('data-field-id');
      if (!fieldId) return;

      if (e.metaKey || e.ctrlKey) {  // Command (Mac) or Control (Windows/Linux)
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          moveField(fieldId, 'up');
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          moveField(fieldId, 'down');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [fields]);

  return (
    <>
      <style>
        {`
          .field-container {
            position: relative;
            transition: transform 0.3s ease;
          }
          
          .field-moved {
            animation: pulse 0.3s ease;
          }

          .reorder-controls {
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 0.2s ease;
            background: rgba(30, 41, 59, 0.8);
            backdrop-filter: blur(4px);
            border-radius: 4px;
            padding: 4px;
            display: flex;
            flex-direction: column;
            gap: 2px;
          }

          .field-container:hover .reorder-controls,
          .field-container:focus-within .reorder-controls {
            opacity: 1;
          }

          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.02); }
            100% { transform: scale(1); }
          }
        `}
      </style>

      <div
        ref={setNodeRef}
        style={{
          minHeight: '400px',
          border: `2px dashed ${isOver ? '#6366f1' : 'rgba(255, 255, 255, 0.2)'}`,
          padding: '16px',
          backgroundColor: isOver ? 'rgba(99, 102, 241, 0.1)' : 'transparent',
          transition: 'all 0.2s ease',
          borderRadius: '8px',
          color: '#fff',
        }}
      >
        {fields.length === 0 && (
          <div style={{ 
            textAlign: 'center', 
            color: 'rgba(255, 255, 255, 0.6)',
            padding: '2rem',
          }}>
            <p>Drag fields here to build your form.</p>
          </div>
        )}
        
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={field.grid?.xs || 12}
              sm={field.grid?.sm || 12}
              md={field.grid?.md || 12}
              lg={field.grid?.lg || 12}
              key={field.id}
            >
              <div 
                className="field-container" 
                data-field-id={field.id}
                tabIndex={0}
              >
                <CanvasField
                  field={field}
                  onUpdate={onUpdateField}
                  onRemove={onRemoveField}
                />
                
                {onReorderFields && (
                  <div className="reorder-controls">
                    <Tooltip title="Move Up (⌘/Ctrl + ↑)" placement="left">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => moveField(field.id, 'up')}
                          disabled={index === 0}
                          sx={{
                            color: 'white',
                            opacity: index === 0 ? 0.3 : 0.8,
                            padding: '4px',
                            '&:hover:not(:disabled)': {
                              opacity: 1,
                              backgroundColor: 'rgba(99, 102, 241, 0.2)',
                            },
                          }}
                        >
                          <KeyboardArrowUpIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    
                    <Tooltip title="Move Down (⌘/Ctrl + ↓)" placement="left">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => moveField(field.id, 'down')}
                          disabled={index === fields.length - 1}
                          sx={{
                            color: 'white',
                            opacity: index === fields.length - 1 ? 0.3 : 0.8,
                            padding: '4px',
                            '&:hover:not(:disabled)': {
                              opacity: 1,
                              backgroundColor: 'rgba(99, 102, 241, 0.2)',
                            },
                          }}
                        >
                          <KeyboardArrowDownIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default FormCanvas;