// src/components/AccordionEditor.tsx

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RichTextEditor from './RichTextEditor';

interface ContentSection {
  id: string;
  title: string;
  content: string;
}

interface AccordionEditorProps {
  sections: ContentSection[];
  onChange: (newSections: ContentSection[]) => void;
}

const AccordionEditor: React.FC<AccordionEditorProps> = ({ sections, onChange }) => {
  const handleTitleChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSections = sections.map(section =>
      section.id === id ? { ...section, title: event.target.value } : section
    );
    onChange(newSections);
  };

  const handleContentChange = (id: string) => (newContent: string) => {
    const newSections = sections.map(section =>
      section.id === id ? { ...section, content: newContent } : section
    );
    onChange(newSections);
  };

  const addSection = () => {
    const newSection: ContentSection = {
      id: Date.now().toString(), // Simple unique id generation
      title: 'New Section',
      content: '',
    };
    onChange([...sections, newSection]);
  };

  const removeSection = (id: string) => () => {
    const newSections = sections.filter(section => section.id !== id);
    onChange(newSections);
  };

  const moveSection = (id: string, direction: 'up' | 'down') => () => {
    const index = sections.findIndex(section => section.id === id);
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === sections.length - 1)
    ) {
      return; // Can't move further
    }

    const newSections = [...sections];
    const [removed] = newSections.splice(index, 1);
    newSections.splice(direction === 'up' ? index - 1 : index + 1, 0, removed);
    onChange(newSections);
  };

  return (
    <div>
      {sections.map((section, index) => (
        <Accordion key={section.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <TextField
                value={section.title}
                onChange={handleTitleChange(section.id)}
                onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling when editing title
                onFocus={(e) => e.stopPropagation()} // Prevent accordion from toggling when focusing on title
              />
              <IconButton
                onClick={removeSection(section.id)}
                size="small"
                sx={{ ml: 'auto' }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={moveSection(section.id, 'up')}
                disabled={index === 0}
                size="small"
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={moveSection(section.id, 'down')}
                disabled={index === sections.length - 1}
                size="small"
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          </AccordionSummary>
  <AccordionDetails
  onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
  onFocus={(e: React.FocusEvent<HTMLDivElement>) => e.stopPropagation()}
>
  <RichTextEditor
    content={section.content}
    onChange={handleContentChange(section.id)}
  />
</AccordionDetails>
        </Accordion>
      ))}
      <Button onClick={addSection} variant="outlined" sx={{ mt: 2 }}>
        Add Section
      </Button>
    </div>
  );
};

export default AccordionEditor;