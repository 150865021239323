import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { FieldType, FieldBlock } from '../../../types/FormSchema';
import { genericInfoBlock } from './genericFields';

interface FieldPaletteItemProps {
  fieldType?: FieldType;
  block?: FieldBlock;
  label: string;
}

const FieldPaletteItem: React.FC<FieldPaletteItemProps> = ({ fieldType, block, label }) => {
  const itemId = block ? `block-${block.id}` : `field-${fieldType}`;
  
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: itemId,
    data: {
      type: block ? 'block' : 'field',
      fieldType,
      block,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
    cursor: 'grab',
    padding: '12px',
    marginBottom: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '6px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    userSelect: 'none' as const,
    touchAction: 'none' as const,
    color: '#fff',
    transition: 'all 0.2s ease',
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {label}
    </div>
  );
};

const FieldPalette: React.FC = () => {
  return (
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '8px',
      padding: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <h3 style={{ 
        marginBottom: '16px', 
        fontSize: '1.2em',
        fontWeight: 600,
        color: '#fff'
      }}>
        Field Palette
      </h3>
      
      <div style={{ marginBottom: '24px' }}>
        <h4 style={{ 
          marginBottom: '12px', 
          fontSize: '0.9em', 
          color: 'rgba(255, 255, 255, 0.6)',
          fontWeight: 500,
          textTransform: 'uppercase',
          letterSpacing: '0.05em'
        }}>
          Blocks
        </h4>
        <FieldPaletteItem block={genericInfoBlock} label="Generic Information" />
      </div>

      <div>
        <h4 style={{ 
          marginBottom: '12px', 
          fontSize: '0.9em', 
          color: 'rgba(255, 255, 255, 0.6)',
          fontWeight: 500,
          textTransform: 'uppercase',
          letterSpacing: '0.05em'
        }}>
          Fields
        </h4>
        <FieldPaletteItem fieldType={FieldType.Text} label="Text Input" />
        <FieldPaletteItem fieldType={FieldType.Email} label="Email Input" />
        <FieldPaletteItem fieldType={FieldType.TextArea} label="Text Area" />
        <FieldPaletteItem fieldType={FieldType.Checkbox} label="Checkbox" />
        <FieldPaletteItem fieldType={FieldType.Select} label="Select" />
        <FieldPaletteItem fieldType={FieldType.Upload} label="File Upload" />
        <FieldPaletteItem fieldType={FieldType.Url} label="URL Input" />
        <FieldPaletteItem fieldType={FieldType.Sig} label="Signature Input" />
      </div>
    </div>
  );
};

export default FieldPalette;