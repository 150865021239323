import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider 
} from '@mui/material';
import { 
  CheckCircleOutline,
  Email,
  Schedule,
  LiveHelp
} from '@mui/icons-material';

interface LocationState {
  referenceId: string;
}

const SubmissionSuccess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const referenceId = state?.referenceId;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      bgcolor="background.default"
      p={2}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          maxWidth: 600, 
          width: '100%', 
          textAlign: 'center',
          borderRadius: 2
        }}
      >
        <CheckCircleOutline 
          color="success" 
          sx={{ fontSize: 60, mb: 2 }} 
        />
        
        <Typography variant="h4" gutterBottom fontWeight="500">
          Application Submitted Successfully!
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Thank you for your application. We're excited to review your submission and will be in touch soon.
        </Typography>

        {referenceId && (
          <Box 
            sx={{ 
              bgcolor: 'grey.50', 
              p: 2, 
              borderRadius: 1,
              mb: 3
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Your Reference ID: <Typography component="span" fontWeight="600">{referenceId}</Typography>
            </Typography>
          </Box>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" sx={{ mb: 2, textAlign: 'left' }}>
          What happens next?
        </Typography>

        <List sx={{ mb: 3, textAlign: 'left' }}>
          <ListItem>
            <ListItemIcon>
              <Schedule color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Review Process" 
              secondary="We'll review your application according to our timeline"
            />
          </ListItem>
          
          <ListItem>
  <ListItemIcon>
    <LiveHelp color="primary" />
  </ListItemIcon>
  <ListItemText 
    primary="Questions?" 
    secondary={
      <>
        If you have any questions about your application, email us at{' '}
        <a href="mailto:admin@improvisedmusic.ie" style={{ color: 'inherit', textDecoration: 'underline' }}>
          admin at improvisedmusic dot ie
        </a>
      </>
    }
  />
</ListItem>
        </List>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/publicstrands')}
            sx={{ px: 4 }}
          >
            Return Home
          </Button>
          
        </Box>
      </Paper>
    </Box>
  );
};

export default SubmissionSuccess;