import axios, { AxiosInstance, AxiosError } from 'axios';
import { toast } from 'react-toastify';

// Type for API Error Response
interface ApiErrorResponse {
  message: string;
  status: number;
}

// Create axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    console.log('Request Config:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      token: token ? 'Present' : 'Not Present',
    });

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    config.withCredentials = true;
    return config;
  },
  (error: AxiosError) => {
    console.error('Request Interceptor Error:', error.message);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response:', {
      status: response.status,
      url: response.config.url,
      dataPresent: !!response.data,
    });
    
    return response;
  },
  (error: AxiosError<ApiErrorResponse>) => {
    console.error('Response Error:', {
      status: error.response?.status,
      url: error.config?.url,
      message: error.response?.data?.message || error.message,
    });

    // Handle authentication errors
    if (error.response && error.response.status === 401) {
      // Clear auth state
      localStorage.removeItem('token');
      
      // Show a toast message before redirect
      toast.error('Your session has expired. Please log in again.');
      
      // Get current path to redirect back after login
      const currentPath = window.location.pathname;
      const returnPath = currentPath !== '/login' ? currentPath : '/';
      
      // Redirect to login with return path and session expired message
      window.location.href = `/login?returnTo=${encodeURIComponent(returnPath)}&sessionExpired=true`;
      return Promise.reject(new Error('Session expired'));
    }

    // Handle other common errors
    if (error.response) {
      if (error.response.status === 403) {
        toast.error('You do not have permission to perform this action');
      } else if (error.response.status === 404) {
        toast.error('The requested resource was not found');
      } else if (error.response.status >= 500) {
        toast.error('A server error occurred. Please try again later');
      }
    }

    return Promise.reject(error);
  }
);

// Export as default
export default axiosInstance;

// Helper function to handle API errors
export const handleApiError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const message = error.response?.data?.message || error.message;
    toast.error(message);
    return message;
  }
  const defaultMessage = 'An unexpected error occurred';
  toast.error(defaultMessage);
  return defaultMessage;
};