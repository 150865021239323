import { IrishCounty } from './BandRecommendations';

export enum InstrumentCategory {
  Keyboard = "Keyboard",
  Strings = "Strings",
  WindBrass = "Wind & Brass",
  Percussion = "Percussion",
  Vocals = "Vocals",
  Other = "Other"
}

export interface CategorizedInstrument {
  value: string;
  label: string;
  category: InstrumentCategory;
}

export const CATEGORIZED_INSTRUMENTS: CategorizedInstrument[] = [
  // Keyboard Instruments
  { value: "Piano", label: "Piano", category: InstrumentCategory.Keyboard },
  { value: "Organ", label: "Organ", category: InstrumentCategory.Keyboard },
  { value: "Synthesizer", label: "Synthesizer", category: InstrumentCategory.Keyboard },
  { value: "Rhodes", label: "Rhodes", category: InstrumentCategory.Keyboard },
  { value: "Wurlitzer", label: "Wurlitzer", category: InstrumentCategory.Keyboard },
  { value: "Harpsichord", label: "Harpsichord", category: InstrumentCategory.Keyboard },
  { value: "Clavinet", label: "Clavinet", category: InstrumentCategory.Keyboard },

  // String Instruments
  { value: "Guitar", label: "Guitar", category: InstrumentCategory.Strings },
  { value: "AcousticGuitar", label: "Acoustic Guitar", category: InstrumentCategory.Strings },
  { value: "ElectricGuitar", label: "Electric Guitar", category: InstrumentCategory.Strings },
  { value: "Bass", label: "Bass", category: InstrumentCategory.Strings },
  { value: "DoubleBass", label: "Double Bass", category: InstrumentCategory.Strings },
  { value: "Violin", label: "Violin", category: InstrumentCategory.Strings },
  { value: "Viola", label: "Viola", category: InstrumentCategory.Strings },
  { value: "Cello", label: "Cello", category: InstrumentCategory.Strings },
  { value: "Banjo", label: "Banjo", category: InstrumentCategory.Strings },
  { value: "Mandolin", label: "Mandolin", category: InstrumentCategory.Strings },
  { value: "Ukulele", label: "Ukulele", category: InstrumentCategory.Strings },
  { value: "Harp", label: "Harp", category: InstrumentCategory.Strings },
  { value: "LapSteelGuitar", label: "Lap Steel Guitar", category: InstrumentCategory.Strings },
  { value: "PedalSteelGuitar", label: "Pedal Steel Guitar", category: InstrumentCategory.Strings },

  // Wind & Brass Instruments
  { value: "Trumpet", label: "Trumpet", category: InstrumentCategory.WindBrass },
  { value: "Trombone", label: "Trombone", category: InstrumentCategory.WindBrass },
  { value: "FrenchHorn", label: "French Horn", category: InstrumentCategory.WindBrass },
  { value: "Tuba", label: "Tuba", category: InstrumentCategory.WindBrass },
  { value: "Flugelhorn", label: "Flugelhorn", category: InstrumentCategory.WindBrass },
  { value: "Cornet", label: "Cornet", category: InstrumentCategory.WindBrass },
  { value: "Saxophone", label: "Saxophone", category: InstrumentCategory.WindBrass },
  { value: "AltoSaxophone", label: "Alto Saxophone", category: InstrumentCategory.WindBrass },
  { value: "TenorSaxophone", label: "Tenor Saxophone", category: InstrumentCategory.WindBrass },
  { value: "BaritoneSaxophone", label: "Baritone Saxophone", category: InstrumentCategory.WindBrass },
  { value: "SopranoSaxophone", label: "Soprano Saxophone", category: InstrumentCategory.WindBrass },
  { value: "Clarinet", label: "Clarinet", category: InstrumentCategory.WindBrass },
  { value: "BassClarinet", label: "Bass Clarinet", category: InstrumentCategory.WindBrass },
  { value: "Flute", label: "Flute", category: InstrumentCategory.WindBrass },
  { value: "Piccolo", label: "Piccolo", category: InstrumentCategory.WindBrass },
  { value: "Oboe", label: "Oboe", category: InstrumentCategory.WindBrass },
  { value: "EnglishHorn", label: "English Horn", category: InstrumentCategory.WindBrass },
  { value: "Bassoon", label: "Bassoon", category: InstrumentCategory.WindBrass },
  { value: "Harmonica", label: "Harmonica", category: InstrumentCategory.WindBrass },

  // Percussion Instruments
  { value: "Drums", label: "Drums", category: InstrumentCategory.Percussion },
  { value: "SnareDrum", label: "Snare Drum", category: InstrumentCategory.Percussion },
  { value: "BassDrum", label: "Bass Drum", category: InstrumentCategory.Percussion },
  { value: "Congas", label: "Congas", category: InstrumentCategory.Percussion },
  { value: "Bongos", label: "Bongos", category: InstrumentCategory.Percussion },
  { value: "Timbales", label: "Timbales", category: InstrumentCategory.Percussion },
  { value: "Cajon", label: "Cajon", category: InstrumentCategory.Percussion },
  { value: "Vibraphone", label: "Vibraphone", category: InstrumentCategory.Percussion },
  { value: "Marimba", label: "Marimba", category: InstrumentCategory.Percussion },
  { value: "Xylophone", label: "Xylophone", category: InstrumentCategory.Percussion },
  { value: "Glockenspiel", label: "Glockenspiel", category: InstrumentCategory.Percussion },
  { value: "Timpani", label: "Timpani", category: InstrumentCategory.Percussion },
  { value: "Triangle", label: "Triangle", category: InstrumentCategory.Percussion },
  { value: "Cowbell", label: "Cowbell", category: InstrumentCategory.Percussion },
  { value: "Cymbals", label: "Cymbals", category: InstrumentCategory.Percussion },
  { value: "HiHat", label: "Hi-Hat", category: InstrumentCategory.Percussion },
  { value: "Tambourine", label: "Tambourine", category: InstrumentCategory.Percussion },
  { value: "Djembe", label: "Djembe", category: InstrumentCategory.Percussion },

  // Vocal Styles
  { value: "Vocals", label: "Vocals", category: InstrumentCategory.Vocals },
  { value: "Soprano", label: "Soprano", category: InstrumentCategory.Vocals },
  { value: "Alto", label: "Alto", category: InstrumentCategory.Vocals },
  { value: "Tenor", label: "Tenor", category: InstrumentCategory.Vocals },
  { value: "Baritone", label: "Baritone", category: InstrumentCategory.Vocals },
  { value: "BassVoice", label: "Bass Voice", category: InstrumentCategory.Vocals },
  { value: "ScatSinging", label: "Scat Singing", category: InstrumentCategory.Vocals },

  // Other Instruments
  { value: "Accordion", label: "Accordion", category: InstrumentCategory.Other },
  { value: "Bagpipes", label: "Bagpipes", category: InstrumentCategory.Other },
  { value: "Didgeridoo", label: "Didgeridoo", category: InstrumentCategory.Other },
  { value: "Melodica", label: "Melodica", category: InstrumentCategory.Other },
  { value: "Theremin", label: "Theremin", category: InstrumentCategory.Other },
  { value: "Kalimba", label: "Kalimba", category: InstrumentCategory.Other },
  { value: "Oud", label: "Oud", category: InstrumentCategory.Other },
  { value: "Sitar", label: "Sitar", category: InstrumentCategory.Other },
  { value: "Zither", label: "Zither", category: InstrumentCategory.Other },
  { value: "Shamisen", label: "Shamisen", category: InstrumentCategory.Other },
  { value: "Dulcimer", label: "Dulcimer", category: InstrumentCategory.Other },
  { value: "Bouzouki", label: "Bouzouki", category: InstrumentCategory.Other },
  { value: "PanFlute", label: "Pan Flute", category: InstrumentCategory.Other },
  { value: "Shakuhachi", label: "Shakuhachi", category: InstrumentCategory.Other },
  { value: "Koto", label: "Koto", category: InstrumentCategory.Other },
  { value: "Guzheng", label: "Guzheng", category: InstrumentCategory.Other },
  { value: "Other", label: "Other", category: InstrumentCategory.Other }
];

// Create an enum from the categorized instruments for type safety
export enum Instrument {
  Piano = "Piano",
  Organ = "Organ",
  Synthesizer = "Synthesizer",
  Rhodes = "Rhodes",
  Wurlitzer = "Wurlitzer",
  Harpsichord = "Harpsichord",
  Clavinet = "Clavinet",
  Guitar = "Guitar",
  AcousticGuitar = "Acoustic Guitar",
  ElectricGuitar = "Electric Guitar",
  Bass = "Bass",
  DoubleBass = "Double Bass",
  Violin = "Violin",
  Viola = "Viola",
  Cello = "Cello",
  Banjo = "Banjo",
  Mandolin = "Mandolin",
  Ukulele = "Ukulele",
  Harp = "Harp",
  LapSteelGuitar = "Lap Steel Guitar",
  PedalSteelGuitar = "Pedal Steel Guitar",
  Trumpet = "Trumpet",
  Trombone = "Trombone",
  FrenchHorn = "French Horn",
  Tuba = "Tuba",
  Flugelhorn = "Flugelhorn",
  Cornet = "Cornet",
  Saxophone = "Saxophone",
  AltoSaxophone = "Alto Saxophone",
  TenorSaxophone = "Tenor Saxophone",
  BaritoneSaxophone = "Baritone Saxophone",
  SopranoSaxophone = "Soprano Saxophone",
  Clarinet = "Clarinet",
  BassClarinet = "Bass Clarinet",
  Flute = "Flute",
  Piccolo = "Piccolo",
  Oboe = "Oboe",
  EnglishHorn = "English Horn",
  Bassoon = "Bassoon",
  Harmonica = "Harmonica",
  Drums = "Drums",
  SnareDrum = "Snare Drum",
  BassDrum = "Bass Drum",
  Congas = "Congas",
  Bongos = "Bongos",
  Timbales = "Timbales",
  Cajon = "Cajon",
  Vibraphone = "Vibraphone",
  Marimba = "Marimba",
  Xylophone = "Xylophone",
  Glockenspiel = "Glockenspiel",
  Timpani = "Timpani",
  Triangle = "Triangle",
  Cowbell = "Cowbell",
  Cymbals = "Cymbals",
  HiHat = "Hi-Hat",
  Tambourine = "Tambourine",
  Djembe = "Djembe",
  Vocals = "Vocals",
  Soprano = "Soprano",
  Alto = "Alto",
  Tenor = "Tenor",
  Baritone = "Baritone",
  BassVoice = "Bass Voice",
  ScatSinging = "Scat Singing",
  Accordion = "Accordion",
  Bagpipes = "Bagpipes",
  Didgeridoo = "Didgeridoo",
  Melodica = "Melodica",
  Theremin = "Theremin",
  Kalimba = "Kalimba",
  Oud = "Oud",
  Sitar = "Sitar",
  Zither = "Zither",
  Shamisen = "Shamisen",
  Dulcimer = "Dulcimer",
  Bouzouki = "Bouzouki",
  PanFlute = "Pan Flute",
  Shakuhachi = "Shakuhachi",
  Koto = "Koto",
  Guzheng = "Guzheng",
  Other = "Other"
}

export interface LocationDate {
  location: string;
  dates: string;
}

export interface NavigatorFormData {
  name: string;
  email: string;
  phone: string;
  instrument: Instrument[];
  location: IrishCounty;
  pronouns: string;
  linkToWork: string;
  webLink: string;
  residencyGoals: string;
  locationDates: string[];
  parentalDuties: string;
  facebookPage?: string;
  twitterHandle?: string;
  instagramHandle?: string;
  tikTokHandle?: string;
}

// Helper function to get instruments by category
export const getInstrumentsByCategory = (category: InstrumentCategory): CategorizedInstrument[] => {
  return CATEGORIZED_INSTRUMENTS.filter(instrument => instrument.category === category);
};

// Helper function to get all categories
export const getAllCategories = (): InstrumentCategory[] => {
  return Object.values(InstrumentCategory);
};