// src/constants/imcCodes.ts

export const IMC_INCOME_CODES = {
  GRANTS_ARTS_COUNCIL: {
    code: '2',
    label: '002 - Grants - Arts Council'
  },
  IMC_WALK_UP_SALES: {
    code: '20',
    label: '020 - IMC - Walk Up Sales'
  },
  IMC_TICKET_SALES: {
    code: '21',
    label: '021 - IMC - Ticket Sales'
  },
  IMC_TICKET_AGENTS_VENUES: {
    code: '23',
    label: '023 - IMC - Ticket Agents - Venues'
  },
  IMC_TICKET_AGENTS_EVENTBRITE: {
    code: '24',
    label: '024 - IMC - Ticket Agents - Eventbrite'
  },
  SERVICES_PROGRAMME_PRODUCTION_FEES: {
    code: '26',
    label: '026 - Services Programme & Production Fees'
  },
  CD_SALES_MERCHANDISE_OTHER_INCOME: {
    code: '30',
    label: '030 - CD Sales - Merchandise - Other Income'
  },
  SERVICES_EQUIPMENT_HIRE_OTHER_INCOME: {
    code: '31',
    label: '031 - Services - Equipment Hire - Other Income'
  },
  MASTERCLASS_SERVICES_OTHER_INCOME: {
    code: '33',
    label: '033 - Masterclass / Services - Other Income'
  },
  TOURING_ARTISTS_SALE_COMMISSION: {
    code: '37',
    label: '037 - Touring Artists - Sale Commission'
  },
  GRANTS_OTHER_ARTS_COUNCIL_FUNDING: {
    code: '5',
    label: '005 - Grants - Other Arts Council Funding'
  },
  GRANTS_LOCAL_AUTHORITY_FUNDING: {
    code: '7',
    label: '007 - Grants - Local Authority Funding'
  },
  GRANTS_OTHER_NATIONAL_FUNDING: {
    code: '6',
    label: '006 - Grants - Other National Funding'
  },
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_DOMESTIC: {
    code: '8',
    label: '008 - Grants/Sponsorship - Cultural Agency - Domestic'
  },
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_INTERNATIONAL: {
    code: '9',
    label: '009 - Grants/Sponsorship - Cultural Agency - International'
  },
  SPONSORSHIP_CORPORATE: {
    code: '10',
    label: '010 - Sponsorship - Corporate'
  },
  SPONSORSHIP_IN_KIND: {
    code: '11',
    label: '011 - Sponsorship - In-Kind'
  },
  INCOME_FROM_EXPENSES: {
    code: '25',
    label: '025 - Income From Expenses'
  },
  TOURING_CONCERTS_PROMOTERS_GUARANTEES: {
    code: '34',
    label: '034 - Touring Concerts - Promoters Guarantees'
  },
  ONCE_OFF_PROJECTS_PROMOTERS_GUARANTEES: {
    code: '27',
    label: '027 - Once Off Projects - Promoters Guarantees'
  },
  FUNDRAISING_DEVELOPMENT_INCOME: {
    code: '13',
    label: '013 - Fundraising & Development Income'
  },
  FUNDRAISED_DONATIONS_FRIENDS_SCHEME: {
    code: '12',
    label: '012 - Fundraised Donations / Friends Scheme'
  }
} as const;

export const IMC_EXPENSE_CODES = {
  ARTISTS_FEES_INTERNATIONAL_JAZZ: {
    code: '110',
    label: '110 - Artist Fees - International Jazz'
  },
  ARTISTS_FEES_INTERNATIONAL_OTHER: {
    code: '111',
    label: '111 - Artist Fees - International Other'
  },
  ARTISTS_FEES_INTERNATIONAL_COLLABORATION: {
    code: '112',
    label: '112 - Artists Fees - International Collaboration'
  },
  VAT_ON_ARTISTS_FEES: {
    code: '113',
    label: '113 - VAT on Artists Fees'
  },
  ARTISTS_FEES_DOMESTIC_JAZZ: {
    code: '114',
    label: '114 - Artists Fees - Domestic Jazz'
  },
  ARTISTS_FEES_DOMESTIC_OTHER: {
    code: '115',
    label: '115 - Artists Fees - Domestic Other'
  },
  DOMESTIC_ARTISTS_ENGAGEMENT: {
    code: '154',
    label: '154 - Artists Fees - Domestic Artists Engagement'
  },
  ARTISTS_DEVELOPMENT_PROJECT_SUBVENTION: {
    code: '160',
    label: '160 - Artists Fees - Development & Project Subvention'
  },
  PRODUCTION_FRONT_OF_HOUSE_CREW: {
    code: '127',
    label: '127 - Production - Front of House Crew'
  },
  PRODUCTION_PA_ENGINEERS: {
    code: '131',
    label: '131 - Production - PA Engineers'
  },
  PRODUCTION_LIGHTING_ENGINEERS: {
    code: '134',
    label: '134 - Production - Lighting Engineers'
  },
  PRODUCTION_PHOTOGRAPHY_VIDEOGRAPHY_PERSONNEL: {
    code: '149',
    label: '149 - Production - Photography/Videography Personnel'
  },
  RECORDINGS_EXPENSES_ARTISTS_RECORDING_FEES: {
    code: '150',
    label: '150 - Recordings Exps - Software'
  },
  RECORDING_EXPENSES_RECORDING_TECHNICIANS: {
    code: '152',
    label: '152 - Recording Expenses - Recording Technicians'
  },
  RECORDING_EXPENSES_DESIGN: {
    code: '153',
    label: '153 - Recording Expenses - Design Personnel'
  },
  PRODUCTION_STAGE_CREW: {
    code: '135',
    label: '135 - Production - Stage Crew'
  },
  PRODUCTION_SPECIALIST_CREW: {
    code: '147',
    label: '147 - Production - Specialist Crew'
  },
  PRODUCTION_EMP_SAFETY_OFFICERS: {
    code: '142',
    label: '142 - Production - EMP / Safety Officers'
  },
  PRODUCTION_OTHER_CREW: {
    code: '126',
    label: '126 - Production - Other Crew'
  },
  ARTISTS_INTERNATIONAL_TRAVEL: {
    code: '116',
    label: '116 - Artists - International Travel'
  },
  ARTISTS_LOCAL_TRANSPORT: {
    code: '117',
    label: '117 - Artists - Local Transport'
  },
  ARTISTS_ACCOMMODATION: {
    code: '118',
    label: '118 - Artists - Accommodation'
  },
  ARTISTS_CATERING_HOSPITALITY: {
    code: '119',
    label: '119 - Artists - Catering & Hospitality'
  },
  ARTISTS_PER_DIEMS: {
    code: '120',
    label: '120 - Artists - Per Diems'
  },
  ARTISTS_MERCHANDISE: {
    code: '122',
    label: '122 - Artists - Merchandise'
  },
  SUNDRY_PRODUCTION_EXPENSES_CONTINGENCIES: {
    code: '141',
    label: '141 - Sundry Production Expenses'
  },
  VENUE_HIRE: {
    code: '143',
    label: '143 - Venue Hire'
  },
  RECORDING_EXPENSES_STUDIO_RENTAL: {
    code: '151',
    label: '151 - Recording Exps - Audio Equipment'
  },
  PRODUCTION_PIANO_HIRE: {
    code: '128',
    label: '128 - Production - Piano Hire'
  },
  PRODUCTION_BACKLINE_HIRE: {
    code: '129',
    label: '129 - Production - Backline Hire'
  },
  PRODUCTION_PA_HIRE: {
    code: '130',
    label: '130 - Production - PA Hire'
  },
  PRODUCTION_BACKLINE_TRANSPORT: {
    code: '148',
    label: '148 - Production - Backline Transport'
  },
  PRODUCTION_EQUIPMENT_HIRE: {
    code: '132',
    label: '132 - Production - Equipment Hire'
  },
  PRODUCTION_LIGHTING_HIRE: {
    code: '133',
    label: '133 - Production - Lighting Hire'
  },
  PRODUCTION_PROPS_STAGING: {
    code: '136',
    label: '136 - Production - Props & Staging'
  },
  PRODUCTION_EQUIPMENT_PURCHASES: {
    code: '161',
    label: '161 - Production - Equipment Purchases'
  },
  ADVERTISING_WEBSITE_DESIGN_EXPENSES: {
    code: '165',
    label: '165 - Advertising - Website Design Expenses'
  },
  ADVERTISING_WEBSITE_MAINTENANCE: {
    code: '166',
    label: '166 - Advertising - Website Maintenance'
  },
  ADVERTISING_WEBSITE_HOSTING_DOMAINS: {
    code: '167',
    label: '167 - Advertising - Website Hosting & Domains'
  },
  MARKETING_POSTER_ADVERTISING: {
    code: '169',
    label: '169 - Marketing - Poster Advertising'
  },
  MARKETING_DESIGN: {
    code: '170',
    label: '170 - Marketing - Design'
  },
  MARKETING_PRINT_MATERIALS: {
    code: '171',
    label: '171 - Marketing - Print Materials'
  },
  MARKETING_POSTER_FLYER_DISTRIBUTION: {
    code: '172',
    label: '172 - Marketing - Poster / Flyer Distribution'
  },
  MARKETING_PHOTOGRAPHY: {
    code: '173',
    label: '173 - Marketing - Photography'
  },
  MARKETING_VIDEOGRAPHY: {
    code: '174',
    label: '174 - Marketing - Videography'
  },
  MARKETING_PUBLICATIONS: {
    code: '175',
    label: '175 - Marketing - Publications'
  },
  MARKETING_PR_ADVERTISING: {
    code: '176',
    label: '176 - Marketing - PR - Advertising'
  },
  MARKETING_ONLINE_ADVERTISING: {
    code: '177',
    label: '177 - Marketing - Online Advertising'
  },
  MARKETING_MERCHANDISE: {
    code: '178',
    label: '178 - Marketing - Merchandise'
  },
  MARKETING_DIGITAL_NEWS_LETTER: {
    code: '179',
    label: '179 - Marketing - Digital Newsletter'
  },
  MARKETING_SUBSCRIPTIONS: {
    code: '187',
    label: '187 - Marketing - Subscriptions & Publications'
  },
  MARKETING_CATERING_HOSPITALITY: {
    code: '146',
    label: '146 - Marketing - Catering & Hospitality'
  },
  TICKET_AGENTS_COMMISSION: {
    code: '144',
    label: '144 - Ticket Agents Commission'
  },
  ARTISTS_VISAS_WORK_PERMITS: {
    code: '121',
    label: '121 - Artists - Visas & Work Permits'
  },
  PRODUCTION_STAFF_TRAVEL: {
    code: '137',
    label: '137 - Production - Staff Travel'
  },
  PRODUCTION_STAFF_SUBSISTENCE_PER_DIEMS: {
    code: '138',
    label: '138 - Production - Staff Subsistence / Per Diems'
  },
  PRODUCTION_STAFF_ACCOMMODATION: {
    code: '139',
    label: '139 - Production - Staff Accommodation'
  },
  PRODUCTION_COURIER_POSTAGE: {
    code: '140',
    label: '140 - Production - Courier / Postage'
  },
  IMRO_PUBLIC_LIABILITY_INSURANCE: {
    code: '145',
    label: '145 - IMRO & Public Liability Insurance'
  },
  OTHER_EXPENSES_NATIONAL_CAMPAIGN_FOR_THE_ARTS: {
    code: '181',
    label: '181 - Other Expenses - National Campaign for the Arts'
  },
  OTHER_EXPENSES_RESEARCH: {
    code: '182',
    label: '182 - Other Expenses - Research'
  },
  OTHER_EXPENSES_SPONSORSHIP_FUNDRAISING_EXPENSES: {
    code: '183',
    label: '183 - Other Expenses - Sponsorship & Fundraising Expenses'
  },
  OTHER_EXPENSES_INSTRUMENT_BACKLINE_MAINTENANCE: {
    code: '184',
    label: '184 - Other Expenses - Instrument / Backline Maintenance'
  },
  EVENT_COLLABORATION_REIMBURSEMENTS: {
    code: '125',
    label: '125 - Event Collaboration Reimbursements'
  },
  OTHER_EXPENSES_IN_KIND_CONTRA: {
    code: '180',
    label: '180 - Other Exps - In Kind / Contra'
  },
  DIRECTOR_EXPENSES_ACCOMMODATION: {
    code: '315',
    label: '315 - Director Expenses - Accommodation'
  },
  DIRECTOR_EXPENSES_TRAVEL: {
    code: '316',
    label: '316 - Director Expenses - Travel'
  },
  DIRECTOR_EXPENSES_SUBSISTENCE: {
    code: '317',
    label: '317 - Director Expenses - Subsistence & Conference'
  },
  MARKETING_EXPENSES_GENERAL: {
    code: '318',
    label: '318 - Marketing Expenses - General'
  },
  COMPANY_EXPENSES_BOARD: {
    code: '319',
    label: '319 - Company Expenses - Board'
  },
  VEHICLE_EXPENSES: {
    code: '320',
    label: '320 - Vehicle Expenses'
  },
  STAFF_EXPENSES_WFH_ALLOWANCE: {
    code: '323',
    label: '323 - Staff Expenses - Working From Home Allowance'
  },
  STAFF_EXPENSES_RECRUITMENT: {
    code: '324',
    label: '324 - Staff Expenses - Recruitment Costs'
  },
  STAFF_EXPENSES_TRAINING: {
    code: '325',
    label: '325 - Staff Expenses - Training & Development'
  },
  STAFF_EXPENSES_TRAVEL: {
    code: '326',
    label: '326 - Staff Expenses - Travel'
  },
  STAFF_EXPENSES_SUBSISTENCE: {
    code: '327',
    label: '327 - Staff Expenses - Subsistence & Conference'
  },
  STAFF_EXPENSES_POST_EVENTS: {
    code: '328',
    label: '328 - Staff Expenses - Post Events'
  },
  COMPANY_EXPENSES_GIFTS: {
    code: '330',
    label: '330 - Company Expenses - Gifts'
  },
  OFFICE_EXPENSES_STATIONERY: {
    code: '331',
    label: '331 - Office Expenses - Stationery'
  },
  OFFICE_EXPENSES_CLEANING: {
    code: '332',
    label: '332 - Office Expenses - Cleaning'
  },
  OFFICE_EXPENSES_COLOR_PRINTING: {
    code: '333',
    label: '333 - Office Expenses - Color Printing'
  },
  OFFICE_EXPENSES_LANDLINE: {
    code: '334',
    label: '334 - Office Expenses - Landline/Broadband'
  },
  MOBILE_EXPENSES_CEO: {
    code: '336-1',
    label: '336 - Office Expenses - Telephone - Mobiles - CEO'
  },
  MOBILE_EXPENSES_GM: {
    code: '336-2',
    label: '336 - Office Expenses - Telephone - Mobiles - GM'
  },
  MOBILE_EXPENSES_MARKETING: {
    code: '336-3',
    label: '336 - Office Expenses - Telephone - Mobiles - Marketing'
  },
  MOBILE_EXPENSES_PRODUCTION: {
    code: '336-4',
    label: '336 - Office Expenses - Telephone - Mobiles - Production'
  },
  MOBILE_EXPENSES_ARTIST_DEV: {
    code: '336-5',
    label: '336 - Office Expenses - Telephone - Mobiles - Artist Dev'
  },
  MOBILE_EXPENSES_COMMS: {
    code: '336-6',
    label: '336 - Office Expenses - Telephone - Mobiles - Comms'
  },
  OFFICE_EXPENSES_POSTAGE: {
    code: '337',
    label: '337 - Office Expenses - Postage/Couriers'
  },
  OFFICE_EXPENSES_COURIERS: {
    code: '338',
    label: '338 - Office Expenses - Couriers (Non-Production)'
  },
  OFFICE_EXPENSES_CANTEEN: {
    code: '339',
    label: '339 - Office Expenses - Canteen'
  },
  OFFICE_EXPENSES_PUBLICATIONS_IRISH_TIMES: {
    code: '340-1',
    label: '340 - Office Expenses - Publications - Irish Times'
  },
  OFFICE_EXPENSES_PUBLICATIONS_OTHER: {
    code: '340-2',
    label: '340 - Office Expenses - Publications - Other'
  },
  OFFICE_EXPENSES_SUNDRY: {
    code: '341',
    label: '341 - Office Expenses - Sundry Expenses Small'
  },
  MARKETING_EXPENSES_WEB_DESIGN: {
    code: '342-1',
    label: '342 - Marketing Expenses - Web Design'
  },
  MARKETING_EXPENSES_VIMEO: {
    code: '342-2',
    label: '342 - Marketing Expenses - Vimeo Pro'
  },
  COMPANY_EXPENSES_IT_HOSTING: {
    code: '344',
    label: '344 - Company Expenses - IT Web Hosting'
  },
  EQUIPMENT_EXPENSES_LEASING_VAN: {
    code: '345-1',
    label: '345 - Equipment Expenses - Leasing - Van'
  },
  EQUIPMENT_EXPENSES_LEASING_2: {
    code: '345-2',
    label: '345 - Equipment Expenses - Leasing #2'
  },
  EQUIPMENT_EXPENSES_LEASING_3: {
    code: '345-3',
    label: '345 - Equipment Expenses - Leasing #3'
  },
  EQUIPMENT_MAINTENANCE: {
    code: '346',
    label: '346 - Equipment Expenses - Maintenance & Repairs'
  },
  EQUIPMENT_FIXTURES: {
    code: '347',
    label: '347 - Equipment Expenses - Misc Fixtures & Fittings (Small)'
  },
  IT_EXPENSES_GOOGLE: {
    code: '348-1',
    label: '348 - IT Expenses - Cloud Services - Google Workspace / YT'
  },
  IT_EXPENSES_MONDAY: {
    code: '348-2',
    label: '348 - IT Expenses - Cloud Services - Monday (Work Platform)'
  },
  IT_EXPENSES_MIRO: {
    code: '348-3',
    label: '348 - IT Expenses - Cloud Services - Miro'
  },
  IT_EXPENSES_EVERNOTE: {
    code: '348-4',
    label: '348 - IT Expenses - Cloud Services - Evernote (Archive)'
  },
  IT_EXPENSES_JOTFORM: {
    code: '348-5',
    label: '348 - IT Expenses - Cloud Services - Jotform (Forms)'
  },
  IT_EXPENSES_BUZZSPROUT: {
    code: '348-6',
    label: '348 - IT Expenses - Cloud Services - Buzzsprout (Podcast)'
  },
  IT_EXPENSES_SURVEYMONKEY: {
    code: '348-7',
    label: '348 - IT Expenses - Cloud Services - Survey Monkey (Surveys)'
  },
  IT_EXPENSES_CALENDLY: {
    code: '348-8',
    label: '348 - IT Expenses - Cloud Services - Calendly'
  },
  IT_EXPENSES_SANEBOX: {
    code: '348-9',
    label: '348 - IT Expenses - Cloud Services - Sanebox (Email Mgmt)'
  },
  IT_EXPENSES_SKEDDA: {
    code: '348-10',
    label: '348 - IT Expenses - Cloud Services - Skedda (Booking System)'
  },
  IT_EXPENSES_MICROSOFT: {
    code: '348-11',
    label: '348 - IT Expenses - Microsoft'
  },
  IT_EXPENSES_DROPBOX: {
    code: '348-12',
    label: '348 - IT Expenses - Cloud Services - Dropbox (Sync/Storage)'
  },
  IT_EXPENSES_SETAPP: {
    code: '348-13',
    label: '348 - IT Expenses - Cloud Services - Setapp (Software)'
  },
  IT_EXPENSES_ZOOM: {
    code: '348-14',
    label: '348 - IT Expenses - Cloud Services - Zoom (Virtual Office)'
  },
  BUILDING_EXPENSES_RENT: {
    code: '351',
    label: '351 - Building Expenses - Rent'
  },
  BUILDING_EXPENSES_STORAGE: {
    code: '352',
    label: '352 - Building Expenses - Document Storage'
  },
  BUILDING_EXPENSES_REPAIRS: {
    code: '353',
    label: '353 - Building Expenses - Repairs & Maintenance'
  },
  BUILDING_EXPENSES_UTILITIES: {
    code: '354',
    label: '354 - Building Expenses - Heating & Lighting'
  },
  BUILDING_EXPENSES_CLEANING: {
    code: '355',
    label: '355 - Building Expenses - Cleaning'
  },
  COMPANY_EXPENSES_INSURANCE: {
    code: '356',
    label: '356 - Company Expenses - Insurance & Employers Liability'
  },
  PROFESSIONAL_EXPENSES_ACCOUNTING_SOFTWARE: {
    code: '357',
    label: '357 - Professional Expenses - Accounting Software(s)'
  },
  PROFESSIONAL_EXPENSES_CONSULTANTS: {
    code: '360',
    label: '360 - Professional Expenses - Consultants'
  },
  SUBSCRIPTION_APPLE_MUSIC: {
    code: '361-1',
    label: '361 - Subscriptions - Apple Music'
  },
  SUBSCRIPTION_SPOTIFY: {
    code: '361-2',
    label: '361 - Subscriptions - Spotify'
  },
  SUBSCRIPTION_AMAZON: {
    code: '361-3',
    label: '361 - Subscriptions - Amazon Prime B2B'
  },
  SUBSCRIPTION_OTHER_1: {
    code: '361-4',
    label: '361 - Subscriptions - Other 1'
  },
  SUBSCRIPTION_OTHER_2: {
    code: '361-5',
    label: '361 - Subscriptions - Other 2'
  },
  PROFESSIONAL_EXPENSES_LEGAL: {
    code: '362',
    label: '362 - Professional Expenses - Legal & Professional'
  },
  PROFESSIONAL_EXPENSES_BOOKKEEPING: {
    code: '363',
    label: '363 - Professional Expenses - Book-Keeping'
  },
  PROFESSIONAL_EXPENSES_AUDIT: {
    code: '364',
    label: '364 - Professional Expenses - Audit'
  },
  PROFESSIONAL_EXPENSES_EJN: {
    code: '365-1',
    label: '365 - Professional Expenses - Membership Fees - EJN'
  },
  PROFESSIONAL_EXPENSES_JPN: {
    code: '365-2',
    label: '365 - Professional Expenses - Membership Fees - JPN'
  },
  SMALL_DIFFERENCES: {
    code: '366',
    label: '366 - Small Differences Write Off'
  },
  BANK_CHARGES: {
    code: '371',
    label: '371 - Bank charges'
  },
  BAD_DEBTS: {
    code: '373',
    label: '373 - Specific Bad Debts Write Off'
  },
  DONATIONS: {
    code: '374',
    label: '374 - Donations'
  },
  EQUIPMENT_DEPRECIATION: {
    code: '349',
    label: '349 - Equipment Expenses - Depreciation'
  },
  EQUIPMENT_DISPOSAL: {
    code: '350',
    label: '350 - Equipment Expenses - Disposal of Equipment'
  }
} as const;

// Helper type for autocomplete and type safety
type IMCCode = {
  code: string;
  label: string;
};

// Helper functions
export const getIMCCodeByCode = (searchCode: string): IMCCode | undefined => {
  const allCodes = { ...IMC_INCOME_CODES, ...IMC_EXPENSE_CODES };
  return Object.values(allCodes).find(item => item.code === searchCode);
};

export const getIMCCodeOptions = (): { value: string; label: string }[] => {
  const allCodes = { ...IMC_INCOME_CODES, ...IMC_EXPENSE_CODES };
  return Object.values(allCodes).map(item => ({
    value: item.code,
    label: item.label
  }));
};