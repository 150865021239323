import React from 'react';
import { RouteObject } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import DomainUserLayout from '../components/layout/DomainUserLayout';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import SubmissionSuccess from '../pages/SubmissionSuccess';




import BandListPage from '../pages/BandListPage';
import EventListPage from '../pages/EventListPage';
import VenueListPage from '../pages/VenueListPage';
import CalendarComponent from '../components/CalendarComponent';
import PerformancesPage from '../pages/PerformanceListPage';
import TitoApiTestPage from '../pages/TitoApiTestPage';
import TicketConfigPage from '../components/TicketConfigPage';

const ProtectedBandListPage = withAuth(BandListPage, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedEventListPage = withAuth(EventListPage, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedVenueListPage = withAuth(VenueListPage, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedCalendarComponent = withAuth(CalendarComponent, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedPerformancesPage = withAuth(PerformancesPage, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedTitoApiTestPage = withAuth(TitoApiTestPage, { allowedRoles: ['domain_user', 'admin'] });
const ProtectedTicketConfigPage = withAuth(TicketConfigPage, { allowedRoles: ['domain_user', 'admin'] });

export const domainUserRoutes: RouteObject[] = [
    {
      element: <DomainUserLayout />,
      children: [
        { path: '/bands', element: <BandListPage /> },
        { path: '/events', element: <EventListPage /> },
        { path: '/venues', element: <VenueListPage /> },
        { path: '/calendar', element: <CalendarComponent /> },
        { path: '/performances', element: <PerformancesPage /> },
        { path: '/sales', element: <TitoApiTestPage /> },
        { path: '/tix', element: <TicketConfigPage /> },
        { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
        { path: '/submission-success', element: <SubmissionSuccess /> },

      ],
    },
  ];