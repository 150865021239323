import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

interface TicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
}

interface Registration {
  completed_at: string;
  tickets_count: number;
}

interface TitoEvent {
  title: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
  registrations: Registration[];
}

interface SalesInterpretationProps {
  event: TitoEvent;
}

const SalesInterpretation: React.FC<SalesInterpretationProps> = ({ event }) => {
  const { totalSold, totalAvailable, ticketTypes, registrations } = event;

  const interpretation = useMemo(() => {
    const messages: string[] = [];

    // Determine how long event has been on sale.
    let onSaleStart: Date;
    if (registrations.length > 0) {
      const earliestReg = registrations
        .map(r => new Date(r.completed_at))
        .sort((a, b) => a.getTime() - b.getTime())[0];
      onSaleStart = earliestReg;
    } else {
      // No registrations yet, assume launched yesterday.
      onSaleStart = new Date();
      onSaleStart.setDate(onSaleStart.getDate() - 1);
    }

    const now = new Date();
    const daysOnSale = Math.floor((now.getTime() - onSaleStart.getTime()) / (1000 * 60 * 60 * 24));

    const isNewOnSale = daysOnSale < 7;    // Event considered "new" if less than a week
    const isLongOnSale = daysOnSale > 30;  // Event considered "long" if more than a month

    const soldRatio = totalAvailable > 0 ? totalSold / totalAvailable : 0;

    // Overall sales interpretation
    if (totalSold === 0) {
      if (isNewOnSale) {
        messages.push("No tickets sold yet, but it's still early days. Keep a close watch over the next week.");
      } else if (isLongOnSale) {
        messages.push("No tickets sold despite being on sale for a long time. Rethink your marketing and pricing strategies.");
      } else {
        messages.push("No tickets sold yet. Consider some initial promotional pushes.");
      }
    } else if (soldRatio < 0.1) {
      if (isNewOnSale) {
        messages.push("Sales are low, but the event just went on sale. Give it a bit more time or a small push.");
      } else {
        messages.push("Sales are quite slow. Consider promotions or discounts to boost interest.");
      }
    } else if (soldRatio < 0.5) {
      messages.push("Sales are steady but not exceptional. Targeted marketing might help accelerate sales.");
    } else if (soldRatio < 0.8) {
      messages.push("Sales are strong. Tickets are moving well, keep up the momentum.");
    } else if (soldRatio < 1.0) {
      messages.push("Tickets are nearing sell-out. Highlight scarcity to sell the remaining tickets quickly.");
    } else {
      messages.push("This event is fully sold out! Consider adding another date or expanding capacity.");
    }

    // Ticket type analysis
    ticketTypes.forEach(tt => {
      const typeRatio = tt.quantity > 0 ? tt.quantity_sold / tt.quantity : 0;
      const ticketsLeft = tt.quantity - tt.quantity_sold;

      if (tt.quantity > 0 && tt.quantity_sold === tt.quantity) {
        // Fully sold out ticket type
        messages.push(`"${tt.name}" tickets are completely sold out. Excellent job!`);
      } else if (typeRatio >= 0.8 && typeRatio < 1.0 && ticketsLeft > 0) {
        // 80% or more sold, but not 100%
        messages.push(`Only ${ticketsLeft} "${tt.name}" tickets left. Urge buyers to act fast!`);
      } else if (typeRatio > 0 && typeRatio < 0.1) {
        // Selling slowly
        if (isNewOnSale) {
          messages.push(`"${tt.name}" tickets are selling slowly, but the event is new on sale. Monitor for a few more days before taking action.`);
        } else if (isLongOnSale) {
          messages.push(`"${tt.name}" tickets have barely moved despite a long time on sale. Consider repositioning, discounts, or better promotion.`);
        } else {
          messages.push(`"${tt.name}" tickets are not moving fast. A targeted promotional campaign might help.`);
        }
      } else if (tt.quantity_sold === 0 && tt.quantity > 0) {
        // No sales at all for this ticket type
        if (isNewOnSale) {
          messages.push(`No "${tt.name}" tickets sold yet, but the event is new. Keep an eye on them before making changes.`);
        } else if (isLongOnSale) {
          messages.push(`"${tt.name}" hasn't sold any tickets after a long period on sale. Reassess pricing, positioning, or marketing.`);
        } else {
          messages.push(`"${tt.name}" hasn't sold yet. Consider a promotional push or repositioning these tickets.`);
        }
      }
    });

    // Check recent sales traction
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const recentSale = registrations.some(reg => new Date(reg.completed_at) >= oneWeekAgo);

    if (!recentSale && totalSold > 0 && !isNewOnSale) {
      messages.push("No recent sales in the last week. Consider a time-limited offer or renewed marketing efforts to rekindle interest.");
    }

    return messages;
  }, [totalSold, totalAvailable, ticketTypes, registrations]);

  return (
    <Box sx={{ mt: 3, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
      <Typography variant="h6" gutterBottom>Sales Interpretation</Typography>
      {interpretation.length === 0 ? (
        <Typography>No specific insights at this time.</Typography>
      ) : (
        interpretation.map((msg, idx) => (
          <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
            - {msg}
          </Typography>
        ))
      )}
    </Box>
  );
};

export default SalesInterpretation;