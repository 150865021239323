import React from 'react';
import { RouteObject } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import ArtistLayout from '../components/layout/ArtistLayout';
import ArtistDashboard from '../pages/Artists/ArtistDashboard';
import ArtistProfileEditPage from '../pages/Artists/ArtistProfileEditPage';
import PublicStrandsPage from '../pages/PublicStrandsPage';
import PublishedBandListPage from '../pages/PublishedBandListPage';
import StrandBandView from '../components/bandpage/StrandBandView';
import BandRecommendationPage from '../pages/BandRecommendationPage';
import BandContainer from '../components/bandpage/BandContainer';
import BanBamPage from '../pages/BanBamPage';
import FormPage from '../pages/FormPage';
import BanBam2025Announcement from '../pages/BanBam2025Announcement';
import MusicAdminPage from '../pages/Artists/MusicAdminPage';
import StageDesignerPage from '../pages/StageDesignerPage';
import ArtistSignupPage from '../pages/Artists/ArtistSignupPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import SubmissionSuccess from '../pages/SubmissionSuccess';



// Protected Components
const ProtectedArtistDashboard = withAuth(ArtistDashboard, { allowedRoles: ['artist'] });
const ProtectedArtistProfileEditPage = withAuth(ArtistProfileEditPage, { allowedRoles: ['artist'] });
const ProtectedBandContainer = withAuth(BandContainer, { allowedRoles: ['artist', 'admin'] });
const ProtectedBandRecommendationPage = withAuth(BandRecommendationPage, { allowedRoles: ['artist'] });

export const artistRoutes: RouteObject[] = [
  {
    element: <ArtistLayout />,

    children: [
      { path: '/artist/dashboard', element: <ProtectedArtistDashboard /> },
      { path: '/artist/signup', element: <ArtistSignupPage /> },
      { path: '/artist/profile', element: <ProtectedArtistProfileEditPage /> },
      { path: '/publicstrands', element: <PublicStrandsPage /> },
      { path: '/strands/:strandSlug', element: <PublishedBandListPage /> },
      { path: '/strands/:strandSlug/band/:bandId', element: <StrandBandView /> },
      { 
        path: '/band/:id', 
        element: <ProtectedBandContainer /> // View route using BandContainer
      },
      { 
        path: '/submit', 
        element: <ProtectedBandRecommendationPage /> // Create new band
      },
      { 
        path: '/band/:id/edit', 
        element: <ProtectedBandRecommendationPage /> // Edit route
      },
      { path: '/banbam', element: <BanBamPage /> },
      { path: '/navigator', element: <FormPage /> },
      { path: '/banbamfaq', element: <BanBam2025Announcement /> },
      { path: '/musicadmin', element: <MusicAdminPage /> },
      { path: '/stage-designer', element: <StageDesignerPage /> },
      { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
      { 
        path: '/navigator/apply', 
        element: <FormPage /> // For new applications
      },
      { 
        path: '/navigator/apply/:draftId', 
        element: <FormPage /> // For continuing draft applications
      },
      { path: '/submission-success', element: <SubmissionSuccess /> },


    ],
  },
];

export default artistRoutes;