import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Box, Paper } from '@mui/material';
import axiosInstance from '../utils/axiosConfig';

interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

interface PopulatedStrand {
  _id: string;
  name: string;
}

interface PopulatedProspect {
  _id: string;
  eventConcept: string;
  date: string;
  status: string;
}

interface PopulatedEvent {
  _id: string;
  name: string;
}

interface PopulatedBand {
  _id: string;
  artistName?: string;
  name?: string;
}

interface PopulatedPerformance {
  _id: string;
  band: PopulatedBand | string;
}

interface PopulatedVenue {
  _id: string;
  name: string;
  capacity: number;
}

interface TicketConfiguration {
  _id: string;
  selectionMethod: 'strand' | 'event';
  strandId?: PopulatedStrand;
  prospectId?: PopulatedProspect;
  eventId?: PopulatedEvent;
  performanceId?: PopulatedPerformance;
  venueId: PopulatedVenue;
  ticketTypes: TicketType[];
  createdAt: string;
  updatedAt: string;
}

const TicketConfigurationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [config, setConfig] = useState<TicketConfiguration | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const res = await axiosInstance.get<TicketConfiguration>(`/api/ticket-configs/${id}`);
        setConfig(res.data);
      } catch (err: any) {
        setError(err.message || 'An error occurred while fetching the configuration details.');
      } finally {
        setLoading(false);
      }
    };
    fetchConfig();
  }, [id]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!config) return <Typography>No configuration found.</Typography>;

  const strandName = config.strandId ? config.strandId.name : 'N/A';
  const prospectName = config.prospectId 
    ? `${config.prospectId.eventConcept} (${new Date(config.prospectId.date).toLocaleDateString()})`
    : 'N/A';
  const eventName = config.eventId ? config.eventId.name : 'N/A';
  let performanceName = 'N/A';
  if (config.performanceId) {
    if (typeof config.performanceId.band === 'object') {
      performanceName = config.performanceId.band.artistName || config.performanceId.band.name || 'Unnamed Band';
    } else {
      performanceName = config.performanceId.band;
    }
  }
  const venueName = config.venueId ? config.venueId.name : 'N/A';

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Ticket Configuration Details
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6">General Information</Typography>
        <Typography>Selection Method: {config.selectionMethod}</Typography>
        <Typography>Strand: {strandName}</Typography>
        <Typography>Prospect: {prospectName}</Typography>
        <Typography>Event: {eventName}</Typography>
        <Typography>Performance: {performanceName}</Typography>
        <Typography>Venue: {venueName} (Capacity: {config.venueId.capacity})</Typography>
        <Typography>Created At: {new Date(config.createdAt).toLocaleString()}</Typography>
        <Typography>Updated At: {new Date(config.updatedAt).toLocaleString()}</Typography>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">Ticket Types</Typography>
        {config.ticketTypes.map((tt, i) => (
          <Box key={i} sx={{ mb: 1 }}>
            <Typography><strong>{tt.name}</strong>: {tt.quantity} x €{tt.price}</Typography>
            <Typography>
              VAT Rate: {tt.vatExempt ? 'Exempt' : `${(tt.vatRate * 100).toFixed(1)}%`} 
              {tt.revenueImpact ? ' (Revenue impacting)' : ''}
            </Typography>
          </Box>
        ))}
      </Paper>
    </Box>
  );
};

export default TicketConfigurationDetail;