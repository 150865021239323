import React, { useEffect } from 'react';
import {
  FormSchema,
  FormField,
  FieldType,
} from '../../../types/FormSchema';
import {
  Box,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Container,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SignatureField from '../SignatureField';

interface FormViewerProps {
  schema: FormSchema;
  values: Record<string, any>;
  onChange: (fieldId: string, value: any) => void;
  disabled?: boolean;
}

const FormViewer = ({
  schema,
  values,
  onChange,
  disabled = false,
}: FormViewerProps) => {
  useEffect(() => {
    console.log('FormViewer rendering with values:', values);
    console.log('Schema fields:', schema.fields.map(f => ({ id: f.id, type: f.type })));
  }, [values, schema]);

  const renderField = (field: FormField) => {
    const fieldValue = values[field.id];
    console.log(`Rendering field ${field.id}:`, { 
      fieldValue,
      fieldType: field.type,
      fullValues: values 
    });

    const value = fieldValue || '';

    const commonProps = {
      id: field.id,
      name: field.id,
      value: value,
      onChange: (e: any) => {
        console.log(`Field ${field.id} change:`, e.target.value);
        onChange(field.id, e.target.value);
      },
      disabled,
      placeholder: field.placeholder,
      fullWidth: true,
      required: field.validation?.required,
      variant: 'outlined' as const,
      margin: 'dense' as const, // Changed to dense for better spacing in grid
    };

    const fieldLabel = (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
        <Typography variant="subtitle1">{field.label}</Typography>
        {field.tooltip && (
          <Tooltip title={field.tooltip}>
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );

    // Your existing switch statement for different field types
    switch (field.type) {
      case FieldType.Text:
      case FieldType.Email:
      case FieldType.Url:
        return (
          <Box sx={{ height: '100%' }}>
            {fieldLabel}
            <TextField
              {...commonProps}
              type={field.type}
              helperText={field.helperText}
            />
          </Box>
        );

      case FieldType.TextArea:
        return (
          <Box sx={{ height: '100%' }}>
            {fieldLabel}
            <TextField
              {...commonProps}
              multiline
              rows={4}
              helperText={field.helperText}
            />
          </Box>
        );

      case FieldType.Select:
        if (!field.options || field.options.length === 0) {
          return (
            <Box sx={{ height: '100%' }}>
              {fieldLabel}
              <FormControl fullWidth variant="outlined" error>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  {...commonProps}
                  label={field.label}
                  margin="none"
                  disabled
                >
                  <MenuItem value="">
                    <em>No options available</em>
                  </MenuItem>
                </Select>
                <FormHelperText>Error: No options provided</FormHelperText>
              </FormControl>
            </Box>
          );
        }
        return (
          <Box sx={{ height: '100%' }}>
            {fieldLabel}
            <FormControl fullWidth variant="outlined">
              <InputLabel>{field.label}</InputLabel>
              <Select
                {...commonProps}
                label={field.label}
                margin="none"
              >
                {field.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {field.helperText && (
                <FormHelperText>{field.helperText}</FormHelperText>
              )}
            </FormControl>
          </Box>
        );

      case FieldType.Checkbox:
        return (
          <Box sx={{ height: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value}
                  onChange={(e) => onChange(field.id, e.target.checked)}
                  disabled={disabled}
                />
              }
              label={field.label}
            />
          </Box>
        );

      case FieldType.Sig:
        return (
          <Box sx={{ height: '100%' }}>
            {fieldLabel}
            <SignatureField
              config={
                field.signatureConfig || {
                  penColor: '#000000',
                  penSize: 2,
                  canvasHeight: 200,
                  backgroundColor: '#ffffff',
                  clearable: true,
                }
              }
              value={value as string}
              onChange={(newValue: string) => onChange(field.id, newValue)}
              disabled={disabled}
              helperText={field.helperText}
            />
          </Box>
        );

      case FieldType.Upload:
        return (
          <Box sx={{ height: '100%' }}>
            {fieldLabel}
            <input
              type="file"
              accept={field.accept}
              multiple={field.multiple}
              onChange={(e) => {
                const files = Array.from(e.target.files || []);
                onChange(field.id, field.multiple ? files : files[0]);
              }}
              disabled={disabled}
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
              }}
            />
            {field.helperText && (
              <FormHelperText>{field.helperText}</FormHelperText>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          {schema.title}
        </Typography>
        <form>
          <Grid 
            container 
            spacing={2} 
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              margin: '0 auto'
            }}
          >
            {schema.fields.map((field) => {
              // Calculate grid sizes with proper fallbacks
              const xs = field.grid?.xs ?? 12;
              const sm = field.grid?.sm ?? xs;
              const md = field.grid?.md ?? sm;
              const lg = field.grid?.lg ?? md;

              return (
                <Grid
                  item
                  xs={xs}
                  sm={sm}
                  md={md}
                  lg={lg}
                  key={field.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '80px', // Ensures consistent field heights
                    mb: 1 // Adds some bottom margin between fields
                  }}
                >
                  {renderField(field)}
                </Grid>
              );
            })}
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default FormViewer;