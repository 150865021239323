import React, { useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Cell
} from 'recharts';

interface TicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
}

interface TitoEvent {
  title: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
}

interface EventSalesChartProps {
  event: TitoEvent;
}

const EventSalesChart: React.FC<EventSalesChartProps> = ({ event }) => {
  // Transform data for Recharts
  const chartData = useMemo(() => {
    return event.ticketTypes.map((type) => {
      return {
        name: type.name,
        sold: type.quantity_sold,
        remaining: Math.max(type.quantity - type.quantity_sold, 0)
      };
    });
  }, [event.ticketTypes]);

  // Choose colors for bars (sold vs remaining)
  const soldColor = '#8884d8';
  const remainingColor = '#82ca9d';

  return (
    <Card elevation={3} sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Sales Visualization
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <strong>Event:</strong> {event.title}<br/>
          <strong>Total Sold:</strong> {event.totalSold} / {event.totalAvailable}<br/>
          <strong>Total Revenue:</strong> €{event.totalRevenue.toFixed(2)}
        </Typography>
        <Box sx={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sold" stackId="a" fill={soldColor} name="Sold">
                {chartData.map((entry, index) => (
                  <Cell key={`cell-sold-${index}`} fill={soldColor} />
                ))}
              </Bar>
              <Bar dataKey="remaining" stackId="a" fill={remainingColor} name="Remaining">
                {chartData.map((entry, index) => (
                  <Cell key={`cell-remaining-${index}`} fill={remainingColor} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventSalesChart;