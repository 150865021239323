
// Irish Counties
export enum IrishCounty {
  // Republic of Ireland (26 counties)
  Carlow = 'Carlow',
  Cavan = 'Cavan',
  Clare = 'Clare',
  Cork = 'Cork',
  Donegal = 'Donegal',
  Dublin = 'Dublin',
  Galway = 'Galway',
  Kerry = 'Kerry',
  Kildare = 'Kildare',
  Kilkenny = 'Kilkenny',
  Laois = 'Laois',
  Leitrim = 'Leitrim',
  Limerick = 'Limerick',
  Longford = 'Longford',
  Louth = 'Louth',
  Mayo = 'Mayo',
  Meath = 'Meath',
  Monaghan = 'Monaghan',
  Offaly = 'Offaly',
  Roscommon = 'Roscommon',
  Sligo = 'Sligo',
  Tipperary = 'Tipperary',
  Waterford = 'Waterford',
  Westmeath = 'Westmeath',
  Wexford = 'Wexford',
  Wicklow = 'Wicklow',
  // Northern Ireland (6 counties)
  Antrim = 'Antrim',
  Armagh = 'Armagh',
  Derry = 'Derry',
  Down = 'Down',
  Fermanagh = 'Fermanagh',
  Tyrone = 'Tyrone'
}