import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import theme from './theme';
import GlobalFontStyles from './components/GlobalFontStyles';
import { JuryAuthProvider } from './contexts/JuryAuthContext';

// Import layouts
import AdminLayout from './components/layout/AdminLayout';
import ArtistLayout from './components/layout/ArtistLayout';
import GuestLayout from './components/layout/GuestLayout';
import DomainUserLayout from './components/layout/DomainUserLayout';
import PublicLayout from './components/layout/PublicLayout';

// Import route configurations
import { publicRoutes } from './routes/publicRoutes';
import { adminRoutes } from './routes/adminRoutes';
import { artistRoutes } from './routes/artistRoutes';
import { domainUserRoutes } from './routes/domainUserRoutes';
import { guestRoutes } from './routes/guestRoutes';

// Import components for public routes
import LoginPage from './pages/LoginPage';
import AuthCallback from './pages/AuthCallback';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <JuryAuthProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <GlobalFontStyles />
              <Router>
                <AppRoutes />
              </Router>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </JuryAuthProvider>
  );
};

const AppRoutes: React.FC = () => {
  const { user, isAuthenticated, loading } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Check for session expired message
    const sessionExpired = searchParams.get('sessionExpired');
    if (sessionExpired === 'true') {
      toast.info('Your session expired. Please log in again.');
    }
  }, [searchParams]);

  // Show loading state
  if (loading) {
    return <div>Loading...</div>; // You might want to replace this with a proper loading component
  }

  if (isAuthenticated && user) {
    console.log("AppRoutes: User role:", user.role);
    switch (user.role) {
      case 'admin':
        return (
          <Routes>
            <Route element={<AdminLayout />}>
              {adminRoutes?.[0]?.children?.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/admin" replace />} />
          </Routes>
        );
      case 'artist':
        return (
          <Routes>
            <Route element={<ArtistLayout />}>
              {artistRoutes?.[0]?.children?.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/artist/dashboard" replace />} />
          </Routes>
        );
      case 'domain_user':
        return (
          <Routes>
            <Route element={<DomainUserLayout />}>
              {domainUserRoutes?.[0]?.children?.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route element={<GuestLayout />}>
              {guestRoutes?.[0]?.children?.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        );
    }
  } else {
    return (
      <Routes>
        <Route element={<PublicLayout />}>
          {publicRoutes?.[0]?.children?.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth-callback" element={<AuthCallback />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }
};

export default App;