// src/constants/paymentMethods.ts

export interface PaymentMethod {
  id: string;
  name: string;
  isPersonal: boolean;
  groupId: string; // Add this field
}

export const PAYMENT_METHODS: PaymentMethod[] = [
  {
    id: 'BUSINESS_CREDIT_DEBIT',
    name: 'BUSINESS Credit/Debit Card',
    isPersonal: false,
    groupId: 'new_group27960' // IMC Debit Card - Direct Debit
  },
  {
    id: 'BUSINESS_SUMUP',
    name: 'BUSINESS SumUp Card',
    isPersonal: false,
    groupId: 'new_group__1' // Sum Up group
  },
  {
    id: 'BUSINESS_PETTY_CASH',
    name: 'BUSINESS Petty Cash',
    isPersonal: false,
    groupId: 'emailed_items' // Default top group if desired
  },
  {
    id: 'PERSONAL_CREDIT_DEBIT',
    name: 'PERSONAL Credit/Debit Card',
    isPersonal: true,
    groupId: 'new_group81791' // Staff Expenses
  },
  {
    id: 'PERSONAL_CASH',
    name: 'PERSONAL Cash',
    isPersonal: true,
    groupId: 'new_group81791' // Staff Expenses
  },
  // If you add more payment methods later, assign groupIds similarly
];

export const getPaymentMethodById = (id: string): PaymentMethod | undefined => {
  return PAYMENT_METHODS.find(method => method.id === id);
};

// Add this function here as well
export const getPaymentMethodGroupId = (id: string): string | undefined => {
  const method = getPaymentMethodById(id);
  return method ? method.groupId : undefined;
};

export const isPersonalPayment = (id: string): boolean => {
  const method = getPaymentMethodById(id);
  return method ? method.isPersonal : false;
};