import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

interface TitoEvent {
  id: string;
  title: string;
  totalSold: number;
  totalAvailable: number;
}

interface GlobalSalesComparisonProps {
  events: TitoEvent[];
}

const GlobalSalesComparison: React.FC<GlobalSalesComparisonProps> = ({ events }) => {
  const summary = useMemo(() => {
    if (events.length === 0) {
      return "No events to compare.";
    }

    // Calculate ratio for each event
    const eventsWithRatio = events.map(evt => ({
      ...evt,
      ratio: evt.totalAvailable > 0 ? evt.totalSold / evt.totalAvailable : 0
    }));

    // Sort by ratio
    const sorted = [...eventsWithRatio].sort((a, b) => b.ratio - a.ratio);
    const best = sorted[0];
    const worst = sorted[sorted.length - 1];

    let message = `Comparing ${events.length} events:\n`;
    message += `- Best performer: "${best.title}" with ${(best.ratio * 100).toFixed(1)}% sold.\n`;
    if (best.id !== worst.id) {
      message += `- Lowest performer: "${worst.title}" with ${(worst.ratio * 100).toFixed(1)}% sold.\n`;
    }

    // Additional suggestion:
    if (best.ratio > 0.8 && worst.ratio < 0.1) {
      message += "Consider redirecting marketing efforts from well-selling events to those struggling to gain traction.";
    } else if (best.ratio > 0.5 && worst.ratio < 0.5) {
      message += "Your top event is performing decently. Use insights from successful strategies to improve weaker events.";
    } else if (best.ratio > 0.8) {
      message += "Your top event is close to a sell-out! Highlight this success and encourage potential buyers to check other events.";
    } else {
      message += "No extreme differences detected. Keep monitoring and adjusting strategies.";
    }

    return message;
  }, [events]);

  return (
    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
      <Typography variant="h6" gutterBottom>Global Sales Comparison</Typography>
      <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
        {summary}
      </Typography>
    </Box>
  );
};

export default GlobalSalesComparison;