import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosConfig';

interface User {
  _id: string;
  id: string;
  email: string;
  name: string;
  role: string;
  isArtist: boolean;
  googleId?: string;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  setToken: (token: string) => void;
  getToken: () => Promise<string | null>;
  googleSignIn: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setTokenState] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const setToken = async (newToken: string) => {
    console.log('AuthContext: Setting token');
    
    try {
      const decoded = JSON.parse(atob(newToken.split('.')[1]));
      console.log('AuthContext: Decoded token:', decoded);
      
      localStorage.setItem('token', newToken);
      setTokenState(newToken);
      await fetchUser(newToken);
    } catch (error) {
      console.error('AuthContext: Error setting token:', error);
      toast.error('Error setting authentication token');
      logout();
    }
  };

  const getToken = async (): Promise<string | null> => {
    console.log('AuthContext: Getting token');
    
    if (token) {
      return token;
    }

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setTokenState(storedToken);
      return storedToken;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
        {},
        { withCredentials: true }
      );
      const newToken = response.data.token;
      await setToken(newToken);
      return newToken;
    } catch (error) {
      console.error('AuthContext: Failed to refresh token:', error);
      logout();
      return null;
    }
  };

  const fetchUser = async (authToken: string) => {
    try {
      console.log('AuthContext: Fetching user with token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      
      const userData = response.data;
      console.log('AuthContext: User data fetched:', userData);
      
      if (!userData.role) {
        console.error('AuthContext: User data missing role');
        throw new Error('User data incomplete');
      }

      setUser(userData);
    } catch (error: any) {
      console.error('AuthContext: Failed to fetch user:', error.response?.data || error.message);
      logout();
      if (error.response?.status === 401) {
        const currentPath = window.location.pathname;
        const returnPath = currentPath !== '/login' ? currentPath : '/';
        window.location.href = `/login?returnTo=${encodeURIComponent(returnPath)}&sessionExpired=true`;
      }
    } finally {
      setLoading(false);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
      if (response.data.token) {
        await setToken(response.data.token);
      } else {
        throw new Error('No token received');
      }
    } catch (error: any) {
      console.error('AuthContext: Login failed:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Login failed');
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setTokenState(null);
    setUser(null);
  };

  const googleSignIn = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
    } catch (error) {
      console.error('AuthContext: Google sign-in failed:', error);
      toast.error('Google sign-in failed');
      throw error;
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      console.log('AuthContext: Found stored token, fetching user');
      fetchUser(storedToken);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated: !!user && !!user.role,
      loading,
      login, 
      logout, 
      setToken,
      getToken,
      googleSignIn
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};